import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import MetaData from '../../../components/MetaData';
import { CreatePlanItemAction, DeletePlanItemAction, SinglePlanAction, UpdatePlanItemAction } from '../../../redux/actions/Admin/PlanAction';
import { CREATE_PLAN_RESET, DELETE_PLAN_RESET, UPDATE_PLAN_RESET } from '../../../redux/constants/Admin/PlanConstant';
import App from '../layouts/App';

import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';

const AdminViewPlan = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { loading, plan, message, isCreated, isUpdated, isDeleted } = useSelector((state) => state.plan);

    const DeletePlanItem = (plan_id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(DeletePlanItemAction(id, plan_id));
        }
    }

    useEffect(() => {
        dispatch(SinglePlanAction(id))
    }, [dispatch, id]);


    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_PLAN_RESET })
        }
        if (isCreated && isCreated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: CREATE_PLAN_RESET })
            setTitle('')
            setCreate(false)
        }
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: UPDATE_PLAN_RESET })
            setTitle('')
            setShow(false)
        }
        dispatch(SinglePlanAction(id))
    }, [dispatch, isDeleted, isCreated, id, isUpdated, message]);

    const [show, setShow] = useState(false);
    const [create, setCreate] = useState(false);
    const [title, setTitle] = useState(null);
    const [itemId, setItemId] = useState(null);

    const handleClose = () => {
        setItemId(null);
        setTitle(null);
        setShow(false)
    };
    const handleShow = (item) => {
        setItemId(item?._id);
        setTitle(item?.title)
        setShow(true)
    };

    const UpdatePlanForm = async (event) => {
        event.preventDefault();
        await dispatch(UpdatePlanItemAction(id, itemId, title));

    }
    const CreatePlanForm = async (event) => {
        event.preventDefault();
        await dispatch(CreatePlanItemAction(id, title))

    }
    return (
        <App>
            <MetaData title={'Admin - Plans'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">{plan?.name}</button>
                            <button type='button' onClick={() => setCreate(true)} className='btn btn-sm rounded-4 bg-blue-1 text-white' ><i className="icon-plus text-12 text-white-1"></i> Create</button>


                            <Modal show={create} onHide={() => setCreate(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Create Plan Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form method='post' onSubmit={CreatePlanForm} className="row x-gap-20 y-gap-20">
                                        <div className="col-12">
                                            <div className="form-input ">
                                                <label className="lh-1 text-16 text-light-1">Title</label>
                                                <input className="form-control bg-light" type="text" onChange={(event) => setTitle(event.target.value)} value={title} required />
                                            </div>
                                        </div>

                                        <div className="d-inline-block pt-30">

                                            <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                Create
                                            </button>

                                        </div>
                                    </form>
                                </Modal.Body>
                            </Modal>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>S#</th>
                                                    <th>Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    plan?.include?.map((item, key) => (
                                                        <tr key={item?._id}>
                                                            <td>{key + 1}</td>
                                                            <td>{item?.title}</td>

                                                            <td>
                                                                <div className="btn-group">



                                                                    <button onClick={() => handleShow(item)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </button>
                                                                    <button onClick={() => DeletePlanItem(item?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }



                                            </tbody>
                                        </table>

                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Update Plan Item</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form method='post' onSubmit={UpdatePlanForm} className="row x-gap-20 y-gap-20">
                                                    <div className="col-12">
                                                        <div className="form-input ">
                                                            <label className="lh-1 text-16 text-light-1">Title</label>
                                                            <input className="form-control bg-light" type="text" onChange={(event) => setTitle(event.target.value)} value={title} required />
                                                        </div>
                                                    </div>

                                                    <div className="d-inline-block pt-30">

                                                        <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                            Update
                                                        </button>

                                                    </div>
                                                </form>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminViewPlan
