import React, { useEffect, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/front/Home'
import NotFound from './pages/front/NotFound'
import About from './pages/front/About'
import AllPackages from './pages/front/AllPackages'
import Contact from './pages/front/Contact'
import Login from './pages/front/Login'
import Store from './redux/Store'
import { AuthUser } from './redux/actions/AuthAction'

import Admin from './middlewares/Admin'
import Vendor from './middlewares/Vendor'

import AdminDashboard from "./pages/Admin/AdminDashboard";

import AdminProfile from './pages/Admin/AdminProfile'
import AdminPassword from './pages/Admin/AdminPassword'

import AdminUsers from './pages/Admin/users/AdminUsers'

import AdminPackages from './pages/Admin/packages/AdminPackages'
import AdminPackageCreate from './pages/Admin/packages/AdminPackageCreate'
import AdminPackageUpdate from './pages/Admin/packages/AdminPackageUpdate'

import AdminCategory from './pages/Admin/category/AdminCategory'
import AdminCategoryCreate from './pages/Admin/category/AdminCategoryCreate'
import AdminCategoryUpdate from './pages/Admin/category/AdminCategoryUpdate'

import AdminAds from './pages/Admin/ads/AdminAds'
import AdminAdsCreate from './pages/Admin/ads/AdminAdsCreate'
import AdminAdsUpdate from './pages/Admin/ads/AdminAdsUpdate'
import AdminWidthdraws from './pages/Admin/widthdraws/AdminWidthdraws'
import VendorRegister from './pages/front/VendorRegister'

import VendorDashboard from "./pages/Vendor/VendorDashboard";
import VendorProfile from './pages/Vendor/VendorProfile'
import VendorPassword from './pages/Vendor/VendorPassword'

import VendorPackages from './pages/Vendor/packages/VendorPackages'
import VendorPackageCreate from './pages/Vendor/packages/VendorPackageCreate'
import VendorPackageUpdate from './pages/Vendor/packages/VendorPackageUpdate'
import AdminBooking from './pages/Admin/booking/AdminBooking'
import SingelPackage from './pages/front/SingelPackage'
import VendorBooking from './pages/Vendor/booking/VendorBooking'
import PrivacyPolicy from './pages/front/PrivacyPolicy'
import TermAndConditions from './pages/front/TermAndConditions'
import Plan from './pages/front/Plan'
import DeleteAccount from './pages/front/DeleteAccount'
import { useSelector } from 'react-redux';
import Loading from './components/Loading'
import AdminPlan from './pages/Admin/plan/AdminPlan'
import AdminViewPlan from './pages/Admin/plan/AdminViewPlan'
import AdminProperty from './pages/Admin/property/AdminProperty'
import AdminPropertyCreate from './pages/Admin/property/AdminPropertyCreate'
import AdminPropertyUpdate from './pages/Admin/property/AdminPropertyUpdate'
import Properties from './pages/front/Properties'
import PropertyDetail from './pages/front/PropertyDetail'
import Visa from './pages/front/Visa'
import VendorVerify from './pages/front/VendorVerify'
import AdminVendors from './pages/Admin/vendors/AdminVendors'
const App = () => {
    useEffect(() => {
        Store.dispatch(AuthUser());
    }, []);

    const { loading } = useSelector((state) => state.auth);
    return (
        loading ? <Loading /> :
            <Suspense fallback={<Loading />}>


                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/delete-account' element={<DeleteAccount />} />
                    <Route path='/packages' element={<AllPackages />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/term-and-conditions' element={<TermAndConditions />} />
                    <Route path='/package/:id' element={<SingelPackage />} />
                    <Route path='/property' element={<Properties />} />
                    <Route path='/property/:id' element={<PropertyDetail />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/plan' element={<Plan />} />
                    <Route path='/visa' element={<Visa />} />

                    <Route path='/login' element={<Login />} />
                    <Route path='/vendor/register' element={<VendorRegister />} />
                    <Route path='/verify' element={<VendorVerify />} />

                    <Route path='/admin/profile' element={<Admin children={<AdminProfile />} />} />
                    <Route path='/admin/password' element={<Admin children={<AdminPassword />} />} />
                    <Route path='/admin/dashboard' element={<Admin children={<AdminDashboard />} />} />

                    <Route path='/admin/users' element={<Admin children={<AdminUsers />} />} />
                    <Route path='/admin/vendors' element={<Admin children={<AdminVendors />} />} />

                    <Route path='/admin/packages' element={<Admin children={<AdminPackages />} />} />
                    <Route path='/admin/package/create' element={<Admin children={<AdminPackageCreate />} />} />
                    <Route path='/admin/package/update/:id' element={<Admin children={<AdminPackageUpdate />} />} />

                    <Route path='/admin/bookings' element={<Admin children={<AdminBooking />} />} />

                    <Route path='/admin/categories' element={<Admin children={<AdminCategory />} />} />
                    <Route path='/admin/category/create' element={<Admin children={<AdminCategoryCreate />} />} />
                    <Route path='/admin/category/update/:id' element={<Admin children={<AdminCategoryUpdate />} />} />

                    <Route path='/admin/plans' element={<Admin children={<AdminPlan />} />} />
                    <Route path='/admin/plan/view/:id' element={<Admin children={<AdminViewPlan />} />} />

                    <Route path='/admin/ads' element={<Admin children={<AdminAds />} />} />
                    <Route path='/admin/ads/create' element={<Admin children={<AdminAdsCreate />} />} />
                    <Route path='/admin/ads/update/:id' element={<Admin children={<AdminAdsUpdate />} />} />

                    <Route path='/admin/widthdraws' element={<Admin children={<AdminWidthdraws />} />} />

                    <Route path='/admin/property' element={<Admin children={<AdminProperty />} />} />
                    <Route path='/admin/property/create' element={<Admin children={<AdminPropertyCreate />} />} />
                    <Route path='/admin/property/update/:id' element={<Admin children={<AdminPropertyUpdate />} />} />

                    <Route path='/vendor/profile' element={<Vendor children={<VendorProfile />} />} />
                    <Route path='/vendor/password' element={<Vendor children={<VendorPassword />} />} />
                    <Route path='/vendor/dashboard' element={<Vendor children={<VendorDashboard />} />} />

                    <Route path='/vendor/packages' element={<Vendor children={<VendorPackages />} />} />
                    <Route path='/vendor/package/create' element={<Vendor children={<VendorPackageCreate />} />} />
                    <Route path='/vendor/package/update/:id' element={<Vendor children={<VendorPackageUpdate />} />} />
                    <Route path='/vendor/bookings' element={<Vendor children={<VendorBooking />} />} />


                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
    )
}

export default App
