import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

import { AuthLogout } from '../../../../redux/actions/AuthAction';

const SideBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const IsActive = window.location.pathname;

    const LogoutFunction = () => {
        localStorage.clear();
        dispatch(AuthLogout());
        toast.error("Logout Successfully...", { theme: "colored" });
        navigate("/")
    }

    return (
        <div className="dashboard__sidebar bg-white scroll-bar-1">
            <div className="sidebar -dashboard">
                <div className="sidebar__item">
                    <a href="/vendor/dashboard" className={`sidebar__button ${IsActive === "/vendor/dashboard" ? "active" : ""}`}>
                        <span className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-home mr-15"></i>
                            Dashboard
                        </span>
                    </a>
                </div>

          

                <div className="sidebar__item">
                    <a href="/vendor/packages" className={`sidebar__button ${IsActive === "/vendor/packages" ? "active" : ""}`}>
                        <span className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-tags mr-15"></i>
                            Packages
                        </span>
                    </a>
                </div>

                <div className="sidebar__item">
                    <a href="/vendor/bookings" className={`sidebar__button ${IsActive === "/vendor/bookings" ? "active" : ""}`}>
                        <span className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-gift mr-15"></i>
                            Bookings
                        </span>
                    </a>
                </div>


                <div className="sidebar__item">
                    <a href="/vendor/profile" className={`sidebar__button ${IsActive === "/vendor/profile" ? "active" : ""}`}>
                        <span className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-user-plus mr-15"></i>
                            Profile
                        </span>
                    </a>
                </div>
                <div className="sidebar__item">
                    <a href="/vendor/password" className={`sidebar__button ${IsActive === "/vendor/password" ? "active" : ""}`}>
                        <span className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-lock mr-15"></i>
                            Password
                        </span>
                    </a>
                </div>

                
                

                <div className="sidebar__item">
                    <div className="sidebar__button ">
                        <button onClick={() => LogoutFunction()} className="d-flex items-center text-15 lh-1 fw-500">
                            <i className="fas fa-sign-out-alt mr-15"></i>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar
