import {
    PLAN_REQUEST,
    PLAN_SUCCESS,
    PLAN_FAIL,

    SINGLE_PLAN_REQUEST,
    SINGLE_PLAN_SUCCESS,
    SINGLE_PLAN_FAIL,

    CREATE_PLAN_REQUEST,
    CREATE_PLAN_SUCCESS,
    CREATE_PLAN_RESET,
    CREATE_PLAN_FAIL,

    UPDATE_PLAN_REQUEST,
    UPDATE_PLAN_SUCCESS,
    UPDATE_PLAN_RESET,
    UPDATE_PLAN_FAIL,

    DELETE_PLAN_REQUEST,
    DELETE_PLAN_SUCCESS,
    DELETE_PLAN_RESET,
    DELETE_PLAN_FAIL,


    CLEAR_ERRORS
} from '../../constants/Admin/PlanConstant'


export const PlanReducer = (state = {}, action) => {
    switch (action.type) {
        case PLAN_REQUEST:
        case SINGLE_PLAN_REQUEST:
        case CREATE_PLAN_REQUEST:
        case UPDATE_PLAN_REQUEST:
        case DELETE_PLAN_REQUEST:
            return {
                loading: true,
            };






        case PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plans: action.payload.plans,
            };

        case SINGLE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plan: action.payload.plan,
            };

        case CREATE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isCreated: true,
            };

        case UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isUpdated: true,
            };
        case DELETE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isDeleted: true,
            };


        case PLAN_FAIL:
        case SINGLE_PLAN_FAIL:
        case CREATE_PLAN_FAIL:
        case UPDATE_PLAN_FAIL:
        case DELETE_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                errors: action.payload,
            };






        case CREATE_PLAN_RESET:
            return {
                loading: false,
                isCreated: false,
            };
        case UPDATE_PLAN_RESET:
            return {
                loading: false,
                isUpdated: false,
            };
        case DELETE_PLAN_RESET:
            return {
                loading: false,
                isDeleted: false,
            };



        case CLEAR_ERRORS:
            return {
                ...state,
                errors: null,
            };

        default:
            return state;
    }
}
