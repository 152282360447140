import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommonBanner from '../../components/CommonBanner';
import MetaData from "../../components/MetaData";
import { WebsiteSinglePackageAction } from '../../redux/actions/WebsiteAction';
import App from './layouts/App';
const SingelPackage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { loading, package: pack } = useSelector((state) => state.website);
    console.log(loading);

    const [mainImageSrc, setMainImageSrc] = useState(pack?.images[0]?.image);
    const handleThumbnailClick = (newSrc) => {
        setMainImageSrc(newSrc);
    };

    useEffect(() => {
        dispatch(WebsiteSinglePackageAction(id))
    }, [dispatch, id])

    useEffect(() => {
        if (!loading) {
            setMainImageSrc(pack?.images[0]?.image);
        }
    }, [loading, pack])

    return (

        <App>
            <MetaData title={'Package - Details'} />
            <CommonBanner heading={pack?.name} />

            {
                loading ? <h2>Loading...</h2> : (
                    <section id="tour_details_main" className="section_padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="tour_details_leftside_wrapper">
                                        <div className="tour_details_heading_wrapper">
                                            <div className="tour_details_top_heading">
                                                <h2>{pack?.name}</h2>

                                                <h5><i className="fas fa-map-marker-alt"></i>
                                                    {
                                                        pack?.location.length > 0 && (
                                                            JSON.parse(pack?.location).map((inc, index) => (
                                                                index === JSON.parse(pack?.location).length - 1 ? inc : `${inc}, `
                                                            ))
                                                        )
                                                    }
                                                </h5>
                                            </div>
                                            <div className="tour_details_top_heading_right">
                                                <h4>{pack?.category?.name}</h4>
                                                {/* <p>(0 reviewes)</p> */}
                                            </div>
                                        </div>


                                        <div class="tour_details_img_wrapper">
                                            <div class="slider slider-for">
                                                <div>
                                                    <img style={{ width: "100%", height: 400 }} id='mainImage' src={mainImageSrc} alt="img" />
                                                </div>
                                            </div>
                                            <div class="slider slider-nav d-flex flex-row">

                                                {
                                                    pack?.images?.map((img, index) => (
                                                        <div key={index} style={{ marginRight: 10, cursor: 'pointer', width: "100%" }} className='col' onClick={() => handleThumbnailClick(img?.image)}>
                                                            <img className='img-thumbnail shadow-sm w-100 h-100' style={{ width: "100%" }} src={img?.image} alt="img" />
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>


                                        <div className="tour_details_boxed">
                                            <h3 className="heading_theme">Overview</h3>
                                            <div className="tour_details_boxed_inner">
                                                <div dangerouslySetInnerHTML={{ __html: pack?.description }} />
                                                <ul>
                                                    <li><i className="fas fa-circle"></i><b>Location</b> &nbsp;:
                                                        {
                                                            pack?.location.length > 0 && (
                                                                JSON.parse(pack?.location).map((inc, index) => (
                                                                    index === JSON.parse(pack?.location).length - 1 ? inc : `${inc}, `
                                                                ))
                                                            )
                                                        }
                                                    </li>
                                                    <li><i className="fas fa-circle"></i><b>Categroy</b> &nbsp;: {pack?.category?.name}</li>
                                                    <li><i className="fas fa-circle"></i><b>Days</b> &nbsp;: {pack?.days}</li>
                                                    <li><i className="fas fa-circle"></i><b>Nights</b> &nbsp;: {pack?.nights}</li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div className="tour_details_boxed">
                                            <h3 className="heading_theme">Included/Excluded</h3>
                                            <div className="tour_details_boxed_inner row">

                                                <div className='col'>
                                                    <ul>
                                                        <h4>Including</h4> <br />
                                                        {
                                                            pack?.including.length > 0 && JSON.parse(pack?.including).map((inc, index) => (

                                                                <li key={index}><i className="fas fa-circle"></i>{inc}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div className='col'>
                                                    <ul>
                                                        <h4>Excluding</h4> <br />
                                                        {
                                                            pack?.excluding.length > 0 && JSON.parse(pack?.excluding).map((exc, index) => (

                                                                <li key={index}><i className="fas fa-circle"></i>{exc}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="tour_details_right_sidebar_wrapper">
                                        <div className="tour_detail_right_sidebar">
                                            <div className="tour_details_right_boxed">
                                                <div className="tour_details_right_box_heading">
                                                    <h3>Price</h3>
                                                </div>
                                                <div className="tour_package_bar_price">
                                                    {
                                                        pack?.downPrice ? (
                                                            <>
                                                                <h6><del>${pack?.orignalPrice}/-</del></h6>
                                                                <h3>${pack?.orignalPrice - pack?.downPrice}/-  </h3>
                                                            </>

                                                        ) : (
                                                            <h3>${pack?.orignalPrice}/- </h3>

                                                        )
                                                    }

                                                </div>

                                                <div className="tour_package_details_bar_list">
                                                    <h5>Info</h5>
                                                    <ul>
                                                        <li><i className="fas fa-circle"></i><b>Location</b> &nbsp;:
                                                            {
                                                                pack?.location.length > 0 && (
                                                                    JSON.parse(pack?.location).map((inc, index) => (
                                                                        index === JSON.parse(pack?.location).length - 1 ? inc : `${inc}, `
                                                                    ))
                                                                )
                                                            }
                                                        </li>
                                                        <li><i className="fas fa-circle"></i><b>Categroy</b> &nbsp;: {pack?.category?.name}</li>
                                                        <li><i className="fas fa-circle"></i><b>Days</b> &nbsp;: {pack?.days}</li>
                                                        <li><i className="fas fa-circle"></i><b>Nights</b> &nbsp;: {pack?.nights}</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                )
            }

        </App>
    )
}

export default SingelPackage
