import React, { useEffect, useState } from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'

import { VendorRegisterAction, VendorVerifyAction, clearErrors } from '../../redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';

const VendorVerify = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading, status, message, isAuthenticated, user, email } = useSelector((state) => state.auth);
    const [otp, setOtp] = useState(null);



    const OTPVerifySubmit = (event) => {
        event.preventDefault();
        dispatch(VendorVerifyAction(otp, email));
    }


    let redirect = "";
    if (user && user.role === "Vendor") {
        redirect = "/vendor/dashboard";
    } else {
        redirect = "/admin/dashboard";
    }

    useEffect(() => {

        if (status && status === 500) {
            toast.error(message, { theme: "colored" });
            dispatch(clearErrors);
        }
       
        if (status && status === 200) {
            toast.success(message, { theme: "colored" });
            window.location.href = redirect;
        }


        if (isAuthenticated === true) {
            window.location.href = redirect;
        }

    }, [dispatch, navigate, status, message, isAuthenticated, redirect])

    return (
        loading ? <Loading /> :
            <App>
                <MetaData title={'Vendor - Otp Verification'} />
                <CommonBanner heading={'Vendor - Otp Verification'} />
                <section id="common_author_area" className="section_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="common_author_boxed">
                                    <div className="common_author_heading">
                                        <h2>Vendor - OTP Verification</h2>
                                    </div>
                                    <div className="common_author_form">
                                        <form method='POST' onSubmit={OTPVerifySubmit} id="main_author_form">
                                            <div className="form-group">
                                                <input type="text"onChange={(event) => setOtp(event.target.value)} value={otp} className="form-control" placeholder="OTP" autoFocus required />
                                            </div>
                                            
                                            <div className="common_form_submit">
                                                <button type='submit' className="btn btn_theme btn_md">Verify</button>
                                            </div>

                                            <div className="have_acount_area">
                                                <p>Back to <a className='text-primary' href="/login">Login</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </App>
    )
}

export default VendorVerify
