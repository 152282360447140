import React, { useEffect, useState } from 'react';

import CommonBanner from '../../components/CommonBanner';
import MetaData from "../../components/MetaData";
import App from './layouts/App';


import { useDispatch, useSelector } from 'react-redux';
import { GetPackagesAction } from '../../redux/actions/Admin/PropertyAction';
import { GetWebsitePackagesAction } from '../../redux/actions/WebsiteAction';

const AllPackages = () => {
    const dispatch = useDispatch();

    const { loading, packages } = useSelector((state) => state.website);

    const [keyword, setKeyword] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [location, setLocation] = useState('');




    const fetchPackages = () => {
        dispatch(GetWebsitePackagesAction({
            keyword,
            sortOrder,
            location,
        }));
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchPackages();
    }, [dispatch, keyword, sortOrder, location]);


    const SearchInputChange = (e) => {
        setKeyword(e.target.value);
    };
    const SortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    const LocationChange = (e) => {
        setLocation(e.target.value);
    };
    return (
        <App>
            <MetaData title={'Packages'} />
            <CommonBanner heading={'Packages'} />


            <section id="explore_area" class="section_padding_top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="section_heading_center">
                                <h2>Explore our hot Packages</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-lg-3">
                            <div className="left_side_search_area">

                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Search by name <a className='text-primary float-end' href="/packages">Clear Filter</a></h5>
                                    </div>
                                    <div className="name_search_form">
                                        <input type="text" className="form-control" value={keyword} onChange={SearchInputChange} placeholder="Name..." />
                                        <button><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Search by location</h5>
                                    </div>
                                    <div className="name_search_form">
                                        <input type="text" className="form-control" value={location} onChange={LocationChange} placeholder="Location..." />
                                        <button><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Filter by Price</h5>
                                    </div>
                                    <div className="name_search_form">
                                        <select type="text" value={sortOrder} onChange={SortOrderChange} className="form-control" placeholder="e.g Deluxe bus">
                                            <option value="HighToLow">High To Low</option>
                                            <option value="LowToLow">Low To Low</option>
                                        </select>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div class="col-lg-9">
                            {
                                loading ? <h5>Loading...</h5> : (
                                    <div class="row">
                                        {
                                            packages?.length > 0 ?
                                                packages?.map((pack) => (
                                                    <a href={`/package/${pack?._id}`} class="col-lg-4 col-md-6 col-sm-6 col-12" key={pack?._id}>
                                                        <div class="theme_common_box_two img_hover">
                                                            <div class="theme_two_box_img">
                                                                <div >
                                                                    <img style={{ height: 200 }} src={pack?.images[0] && pack?.images[0]?.image} alt="img" />
                                                                </div>
                                                                <p><i class="fas fa-map-marker-alt"></i>
                                                                    {
                                                                        pack?.location.length > 0 && (
                                                                            JSON.parse(pack?.location).map((inc, index) => (
                                                                                index === JSON.parse(pack?.location).length - 1 ? inc : `${inc}, `
                                                                            ))
                                                                        )
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div class="theme_two_box_content">
                                                                <h4><span>{pack?.name.length > 30 ? pack?.name.substring(0, 30) + "..." : pack?.name}</span></h4>
                                                                <p>
                                                                    {
                                                                        pack?.downPrice ? (<span style={{ textDecorationLine: "line-through" }} class="review_rating">${pack?.orignalPrice}/-</span>) : ''
                                                                    }
                                                                    <span class="review_count">({pack?.days} days & {pack?.nights} nights)</span>
                                                                </p>
                                                                {
                                                                    pack?.downPrice ? (
                                                                        <h3>${pack?.orignalPrice - pack?.downPrice}/-<span>{pack?.category?.name}</span></h3>

                                                                    ) : (
                                                                        <h3>${pack?.orignalPrice}/-<span> &nbsp;{pack?.category?.name}</span></h3>

                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    </a>
                                                ))
                                                : (
                                                    <h5 className='text-center mt-5'>No Packages found...</h5>
                                                )
                                        }
                                    </div>

                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </App>
    )
}

export default AllPackages
