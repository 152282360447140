import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { authReducer, updateProfileReducer } from './reducers/AuthReducer';
import { UsersReducer } from './reducers/Admin/UserReducer';
import { PackagesReducer } from './reducers/Admin/PackageReducer';
import { CategoryReducer } from './reducers/Admin/CategroyReducer';
import { BannerReducer } from './reducers/Admin/BannerReducer';
import { WidthdrawReducer } from './reducers/Admin/WidthdrawReducer';
import { BookingReducer } from './reducers/Admin/BookingReducer';
import { WebsiteReducer } from './reducers/WebsiteReducer';
import { PlanReducer } from './reducers/Admin/PlanReducer';
import { PropertyReducer } from './reducers/Admin/PropertyReducer';



const reducer = combineReducers({
    // WebSite
    website : WebsiteReducer,

    // Auth
    auth: authReducer,
    profile: updateProfileReducer,

    // Admin
    users: UsersReducer,
    packages: PackagesReducer,
    categories: CategoryReducer,
    bookings: BookingReducer,
    banners: BannerReducer,
    widthdraw: WidthdrawReducer,
    plan: PlanReducer,
    property: PropertyReducer,


});

let initialState = {};

const middleware = [thunk];

const Store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;