import React from 'react'
import Header from './include/Header'
import Footer from './include/Footer'
const App = ({ children }) => {
    return (
        <React.Fragment>
            {/* <div className="preloader">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Header />
            {children}

            <Footer />
            <div className="go-top">
                <i className="fas fa-chevron-up"></i>
                <i className="fas fa-chevron-up"></i>
            </div>
            <div className="copyright_area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="copyright_left">
                                <p>Copyright © 2023 All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="copyright_right">
                                <img src="/assets/img/common/cards.png" alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default App
