import React, { useEffect } from 'react'
import App from './layouts/App'
import MetaData from '../../components/MetaData'
import { useDispatch } from 'react-redux'
import { getAdminUsers } from '../../redux/actions/Admin/UserAction'
import { GetPackagesAction } from '../../redux/actions/Admin/PackageAction'
import { GetCategoriesAction } from '../../redux/actions/Admin/CategoryAction'
import { GetBannersAction } from '../../redux/actions/Admin/BannerAction'
import { useSelector } from 'react-redux'
import { GetWidthdrawAction } from '../../redux/actions/Admin/WidthdrawAction'
import Loading from '../../components/Loading'
import { GetBookingsAction } from '../../redux/actions/Admin/BookingAction'
import { GetPlansAction } from '../../redux/actions/Admin/PlanAction'
import { GetPropertiesAction } from '../../redux/actions/Admin/PropertyAction'

const AdminDashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminUsers());
        dispatch(GetPackagesAction());
        dispatch(GetCategoriesAction());
        dispatch(GetBannersAction());
        dispatch(GetWidthdrawAction());
        dispatch(GetBookingsAction());
        dispatch(GetPlansAction());
        dispatch(GetPropertiesAction());
    }, [dispatch]);


    const { loading, users } = useSelector((state) => state.users);
    const { packages } = useSelector((state) => state.packages);
    const { categories } = useSelector((state) => state.categories);
    const { banners } = useSelector((state) => state.banners);
    const { widthdraws } = useSelector((state) => state.widthdraw);
    const { bookings } = useSelector((state) => state.bookings);
    const { plans } = useSelector((state) => state.plan);
    const { properties } = useSelector((state) => state.property);
    return (
        <App>
            <MetaData title={'Admin - Dashboard'} />
            <div className="row y-gap-20 justify-between items-end pb-20 lg:pb-40 md:pb-32">
                <div className="col-auto">
                    <h1 className="text-30 lh-14 fw-500">Dashboard</h1>
                </div>

                <div className="col-auto">

                </div>
            </div>

            <div style={{ height: "80vh" }}>
                {
                    loading ? <Loading /> :
                        <div className="row y-gap-30">

                            <a href='/admin/users' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-15 custom_border_left_blue custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Users</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_blue">{users?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-1.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>



                            <a href='/admin/categories' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_sky custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Categories</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_sky">{categories?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-3.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href='/admin/packages' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_oreng custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Packages</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_oreng">{packages?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-2.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href='/admin/bookings' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_green custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Bookings</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_green">{bookings?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-4.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>



                            <a href='/admin/ads' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_green custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Ads</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_green">{banners?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-4.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a href='/admin/widthdraws' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_blue custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Widthdraw</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_blue">{widthdraws?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_blue">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-4.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a href='/admin/plans' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_sky custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Plans</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_sky">{plans?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-3.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a href='/admin/property' className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="py-15 px-30 custom_border_left_oreng custom_rounded bg-white custom_shadow">
                                    <div className="row y-gap-20 justify-between items-center">
                                        <div className="col-md-6">
                                            <div className="fw-500 lh-14">Property</div>
                                            <div className="text-30 lh-16 fw-600 mt-5 text_oreng">{properties?.length}</div>
                                            <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                        </div>

                                        <div className="col-md-6">
                                            <img src="/backend/img/dashboard/icons/arrow-2.png" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>

                        </div>
                }
            </div>




        </App>
    )
}

export default AdminDashboard
