import React, { useEffect } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { DeleteBookingAction, GetBookingsAction, UpdateBookingStatusAction } from '../../../redux/actions/Admin/BookingAction';
import { DELETE_BOOKING_RESET, UPDATE_BOOKING_STATUS_RESET } from '../../../redux/constants/Admin/BookingConstant';

import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { useState } from 'react';
import { truncate } from '../../../incude/truncate';
const AdminBooking = () => {
    const dispatch = useDispatch();

    const { loading, bookings, message, isDeleted, isUpdated } = useSelector((state) => state.bookings);


    const [data, setData] = useState({
        status: "",
        remarks: "",
    });

    const InpChnage = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }
    const UpdateStatusFunc = (event, id1) => {
        event.preventDefault();
        handleClose();
        dispatch(UpdateBookingStatusAction(id1, data.status, data.remarks));
    }

    const DeleteBooking = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(DeleteBookingAction(id));
        }
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_BOOKING_RESET })
        }
        if (isUpdated && isUpdated === true) {
            handleClose();
            toast.success(message, { theme: "colored" });
            dispatch({ type: UPDATE_BOOKING_STATUS_RESET })

        }
        dispatch(GetBookingsAction())
    }, [dispatch, isDeleted, isUpdated, message]);




    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(null);

    const handleClose = () => {
        setIndex(null);
        setShow(false);
    }
    const handleShow = (ind) => {
        setData({ 
            status: bookings?.[ind]?.status ?? "",
            remarks: bookings?.[ind]?.remarks ?? "",
         });
        setIndex(ind);
        setShow(true);
    };




    return (
        <App>
            <MetaData title={'Admin - Bookings'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Bookings</button>
                        </div>

                    </div>




                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>User</th>
                                                    {/* <th>User Email</th> */}
                                                    <th>Pacakge</th>
                                                    <th>Category</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    bookings?.map((booking, index) => (
                                                        <tr key={booking?._id}>
                                                            <td>
                                                                <img src={booking?.user?.image?.length > 0 ? booking?.user?.image : "/placeholder.jpg"} alt="image1" className="size-40 rounded-2 object-cover" />&nbsp;

                                                                {truncate(booking?.user?.name, 20)}
                                                            </td>
                                                            {/* <td>{booking?.user?.email}</td> */}
                                                            <td>
                                                                <img src={booking?.package?.images ? booking?.package?.images[0]?.image : "/placeholder.jpg"} alt="image1" className="size-50 rounded-2 object-cover" />&nbsp;
                                                                {truncate(booking?.package?.name, 20)}
                                                            </td>
                                                            <td>{booking?.package?.category?.name}</td>
                                                            <td> ${booking?.package?.downPrice ? booking?.package?.orignalPrice - booking?.package?.downPrice : booking?.package?.orignalPrice}/-</td>
                                                            <td>
                                                                {booking?.status === "Processing" && <span className='badge bg-info'>{booking?.status}</span>}
                                                                {booking?.status === "Confirm" && <span className='badge bg-success'>{booking?.status}</span>}
                                                                {booking?.status === "Reject" && <span className='badge bg-danger'>{booking?.status}</span>}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button onClick={() => handleShow(index)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </button>


                                                                    <button onClick={() => DeleteBooking(booking?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>





                                                        </tr>
                                                    ))
                                                }

                                                <Modal show={show} onHide={handleClose} size='lg'>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Booking Info </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        {
                                                            index !== null ? (
                                                                <div className="col-xl-12">
                                                                    <div className="p-2">
                                                                        <div className="row" style={{ borderBottom: '1px solid rgba(33, 40, 50, 0.125)' }}>
                                                                            <div className="col-md-4">
                                                                                <label className="mb-1  font-weight-bolder" style={{ fontWeight: 'bold' }}>Number of Persons</label><br />
                                                                                <label className="mb-1">{bookings[index]?.number_of_persons}</label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label className="mb-1 font-weight-bolder" style={{ fontWeight: 'bold' }}>Name</label><br />
                                                                                <label className="mb-1">{bookings[index]?.name}</label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label className="mb-1 font-weight-bolder" style={{ fontWeight: 'bold' }}>Email</label><br />
                                                                                <label className="mb-1">{bookings[index]?.email}</label>
                                                                            </div>


                                                                        </div>
                                                                        <br />
                                                                        <div className="row" style={{ borderBottom: '1px solid rgba(33, 40, 50, 0.125)' }}>
                                                                            <div className="col-md-4">
                                                                                <label className="mb-1 font-weight-bolder" style={{ fontWeight: 'bold' }}>Travel Date</label><br />
                                                                                <label className="mb-1">{moment(bookings[index]?.travel_date).format('DD MMM yyyy, hh:mm A')}</label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label className="mb-1 font-weight-bolder" style={{ fontWeight: 'bold' }}>Status</label><br />
                                                                                <label className="mb-1">{bookings[index]?.status}</label>
                                                                            </div>


                                                                        </div>
                                                                        <br />


                                                                    </div>
                                                                    <form method='post' onSubmit={(event) => UpdateStatusFunc(event, bookings[index]?._id)} className="row x-gap-20 y-gap-20">
                                                                        <div className="col-12">
                                                                            <div className="form-input ">
                                                                                <label className="lh-1 text-16 text-light-1">Status</label>
                                                                                <select onChange={InpChnage} name='status' value={data.status} className='form-control' required>
                                                                                    <option value="">Choose</option>
                                                                                    <option value="Processing">Processing</option>
                                                                                    <option value="Confirm">Confirm</option>
                                                                                    <option value="Reject">Reject</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="form-input ">
                                                                                <label className="lh-1 text-16 text-light-1">Remarks</label>
                                                                                <input onChange={InpChnage} type="text" name='remarks' value={data.remarks} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-inline-block pt-30">

                                                                            <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white w-100 ">
                                                                                Update
                                                                            </button>

                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            )
                                                                :
                                                                <>
                                                                    <h4>Loading....</h4>
                                                                </>
                                                        }
                                                    </Modal.Body>
                                                </Modal>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminBooking
