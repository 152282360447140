import React, { useEffect, useState } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { CREATE_CATEGORY_RESET } from '../../../redux/constants/Admin/CategroyConstant';
import { CreateCategoryAction } from '../../../redux/actions/Admin/CategoryAction';
import { useNavigate } from 'react-router-dom';

const AdminCategoryCreate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, message, isCreated } = useSelector((state) => state.categories);

    const [categroy, setCategroy] = useState(null);

    const CreateCategroyForm = (event) => {
        event.preventDefault();
        dispatch(CreateCategoryAction(categroy));
    }

    useEffect(() => {
        if (isCreated && isCreated === true) {
            toast.success(message, { theme: "colored" })
            dispatch({ type: CREATE_CATEGORY_RESET })
            setCategroy('');
        }
    }, [dispatch, navigate, isCreated, message]);

    return (
        <App>
            <MetaData title={'Admin - Create Category'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Create Category</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/categories"><i className="icon-arrow-left text-12 text-white-1"></i> Back</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">
                                <div className="tabs__pane -tab-item-1 is-tab-el-active">


                                    <div className="col-xl-12">
                                        <form method='post' onSubmit={CreateCategroyForm} className="row x-gap-20 y-gap-20">
                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Categroy Name</label>
                                                    <input type="text" onChange={(event) => setCategroy(event.target.value)} value={categroy} name='name' required />
                                                </div>
                                            </div>
                                            <div className="d-inline-block pt-30">

                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                    Create
                                                </button>

                                            </div>
                                        </form>
                                    </div>


                                </div>


                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminCategoryCreate
