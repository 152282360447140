export const PLAN_REQUEST = "PLAN_REQUEST";
export const PLAN_SUCCESS = "PLAN_SUCCESS";
export const PLAN_FAIL = "PLAN_FAIL";

export const SINGLE_PLAN_REQUEST = "SINGLE_PLAN_REQUEST";
export const SINGLE_PLAN_SUCCESS = "SINGLE_PLAN_SUCCESS";
export const SINGLE_PLAN_FAIL = "SINGLE_PLAN_FAIL";

export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_RESET = "CREATE_PLAN_RESET";
export const CREATE_PLAN_FAIL = "CREATE_PLAN_FAIL";

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_RESET = "UPDATE_PLAN_RESET";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";

export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_RESET = "DELETE_PLAN_RESET";
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
