import React from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
const TermAndConditions = () => {
    return (
        <App>
            <MetaData title={'Term and Conditions'} />
            <CommonBanner heading={'Term and Conditions'} />

            <section id="tour_details_main" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="terms_service_content">
                                <div className="terms_item">
                                    <h2>Terms and Conditions of Use for AptoPass App and Website
                                        (www.aptopass.com)</h2>

                                    <p>These terms and conditions ("Terms") govern your
                                        access to and use of the AptoPass mobile application ("App") and the
                                        AptoPass website ("Website") available at www.aptopass.com. By
                                        accessing or using the App or Website, you agree to be bound by these Terms,
                                        which constitute a legally binding agreement between you and AptoPass. If you
                                        do not agree to these Terms, you may not use the App or Website.</p>

                                    <p>1. Eligibility:</p>

                                    <p>1.1 Age Requirement: You must be at least 18 years old to use the App or Website.</p>

                                    <p>1.2 Account Registration: You may be required to create an account to access certain
                                        features of the App or Website. You agree to provide accurate and complete
                                        information during the registration process and to keep your account
                                        credentials secure.</p>

                                    <p>2. Use of the App and Website:</p>

                                    <p>2.1 Limited License: AptoPass grants you a limited, non-exclusive, non-transferable license
                                        to access and use the App and Website for personal, non-commercial purposes, in
                                        accordance with these Terms.</p>

                                    <p>2.2 Prohibited Activities: You agree not to engage in any activities that may:</p>

                                    <p> Violate any
                                        applicable laws, regulations, or third-party rights.</p>

                                    <p> Interfere with or disrupt the
                                        functioning of the App or Website.</p>

                                    <p> Gain
                                        unauthorized access to any systems or data related to the App or Website.</p>

                                    <p> Use the App
                                        or Website to transmit any harmful or malicious content.</p>

                                    <p>3. Bookings and Transactions:</p>

                                    <p>3.1 Property Listings: The App and Website provide information about various accommodations,
                                        including descriptions, images, availability, and pricing. However, AptoPass
                                        does not guarantee the accuracy or completeness of such information.</p>

                                    <p>3.2 Bookings: When making a booking through the App or Website, you agree to comply with the
                                        specific terms and conditions provided by the property owner or manager.
                                        AptoPass is not responsible for the fulfillment of bookings or any issues
                                        arising between you and the property owner.</p>

                                    <p>3.3 Payments: All payments made through the App or Website are subject to the terms and
                                        conditions of the respective payment processors. AptoPass does not process or
                                        store your payment information directly.</p>

                                    <p>4. Intellectual Property:</p>

                                    <p>4.1 Ownership: The App, Website, and all content, trademarks, logos, and intellectual property
                                        rights associated with them are owned by AptoPass or its licensors.</p>

                                    <p>4.2 Restrictions: You may not copy, modify, distribute, or otherwise use any content from the App
                                        or Website without prior written permission from AptoPass.</p>

                                    <p>5. Privacy:</p>

                                    <p>5.1 Data Collection: AptoPass collects and processes personal information in accordance
                                        with its Privacy Policy, which is available on the App and Website.</p>

                                    <p>5.2 Cookies: The App and Website use cookies and similar technologies to enhance user experience
                                        and collect usage data. By using the App or Website, you consent to the use of
                                        cookies as described in the Cookie Policy.</p>

                                    <p>6. Limitation of Liability:</p>

                                    <p>6.1 Disclaimer: The App and Website are provided on an "as is" and "as
                                        available" basis, without warranties of any kind, including implied
                                        warranties of merchantability, fitness for a particular purpose, or
                                        non-infringement.</p>

                                    <p>6.2 Indemnification: You agree to indemnify and hold harmless AptoPass and its
                                        affiliates from any claims, damages, liabilities, or expenses arising out of
                                        your use of the App or Website.</p>

                                    <p>7. Governing Law and Dispute Resolution:</p>

                                    <p>7.1 Governing Law: These Terms shall be governed by and construed</p>

                                    <p>in accordance with
                                        the laws of India.</p>

                                    <p>7.2 Jurisdiction: Any disputes arising out of or in connection with these Terms shall be subject
                                        to the exclusive jurisdiction of the courts located in [Moga], [Punjab],
                                        [India].</p>

                                    <p>8. Amendments:</p>

                                    <p>AptoPass reserves the right to modify or update these Terms at any time. Any changes will be
                                        effective upon posting the revised Terms on the App and Website. Your continued
                                        use of the App or Website after the posting of any changes constitutes your
                                        acceptance of such changes.</p>

                                    <p>If you have any questions or concerns regarding these Terms,
                                        please contact us at [aptopass@gmail.com].</p>

                                    <p>Last updated: [14 Mar 2024]</p>

                                    <p>By using AptoPass App and Website, you acknowledge that you
                                        have read, understood, and agreed to these Terms and Conditions.</p><br /><p></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </App>
    )
}

export default TermAndConditions
