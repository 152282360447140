import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import MetaData from '../../../components/MetaData';
import { DeletePropertyAction, GetPropertiesAction } from '../../../redux/actions/Admin/PropertyAction';
import { DELETE_PROPERTY_RESET } from '../../../redux/constants/Admin/PropertyConstant';
import App from '../layouts/App';

const AdminProperty = () => {
    const dispatch = useDispatch();

    const { loading, properties, message, isDeleted } = useSelector((state) => state.property);

    const DeleteProperty = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(DeletePropertyAction(id));
        }
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_PROPERTY_RESET })
        }
        dispatch(GetPropertiesAction())
    }, [dispatch, isDeleted, message]);

    return (
        <App>
            <MetaData title={'Admin - Properties'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Properties</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/property/create"><i className="icon-plus text-12 text-white-1"></i> Create</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Property</th>
                                                    <th>Price</th>
                                                    <th>Rooms</th>
                                                    <th>Type</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    properties?.map((property) => (
                                                        <tr key={property?._id}>
                                                            <td>
                                                                <img src={property?.galleries[0] && property?.galleries[0]?.image ? property?.galleries[0]?.image : "/placeholder.jpg"} alt="-" className="size-50 rounded-2 object-cover" />&nbsp;
                                                                {property?.name}
                                                            </td>
                                                            <td>$ {property?.price}</td>
                                                            <td>{property?.rooms}</td>
                                                            <td>{property?.type}</td>

                                                            <td>
                                                                <div className="btn-group">
                                                                    <a href={`/admin/property/update/${property?._id}`} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </a>

                                                                    <button onClick={() => DeleteProperty(property?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminProperty
