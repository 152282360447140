import React, { useEffect } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import moment from 'moment';
import { DeleteWidthdrawAction, GetWidthdrawAction, UpdateWidthdrawStatusAction } from '../../../redux/actions/Admin/WidthdrawAction';
import { DELETE_WIDTHDRAW_RESET, UPDATE_WIDTHDRAW_RESET } from '../../../redux/constants/Admin/WidthdrawConstant';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
const AdminWidthdraws = () => {
    const dispatch = useDispatch();

    const { loading, widthdraws, message, isDeleted, isUpdated } = useSelector((state) => state.widthdraw);

    const DeleteWidthdraw = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(DeleteWidthdrawAction(id));
        }
    }

    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("");



    const UpdateWidthdrawStatusFunction = (event, id) => {
        event.preventDefault();
        dispatch(UpdateWidthdrawStatusAction(id, status));
    }
    useEffect(() => {
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: UPDATE_WIDTHDRAW_RESET })
            setShow(false);
        }
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_WIDTHDRAW_RESET })
        }
        dispatch(GetWidthdrawAction())
    }, [dispatch, isDeleted, isUpdated, message]);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <App>
            <MetaData title={'Admin - Widthdraws'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Widthdraws</button>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>User</th>
                                                    <th>S#</th>
                                                    <th>Email</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>RegisterAt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    widthdraws?.map((widthdraw) => (
                                                        <tr key={widthdraw?._id}>
                                                            <td>
                                                                <img src={widthdraw?.user?.image?.length > 0 ? widthdraw?.user?.image : "/placeholder.jpg"} alt="image1" className="size-40 rounded-2 object-cover" />&nbsp;
                                                                {widthdraw?.user?.name}
                                                            </td>
                                                            <td>{widthdraw?._id}</td>
                                                            <td>{widthdraw?.user?.email}</td>
                                                            <td>${widthdraw?.amount}</td>
                                                            <td>

                                                                {
                                                                    widthdraw?.status === "Pending" && <span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">{widthdraw?.status}</span>
                                                                }
                                                                {
                                                                    widthdraw?.status === "Confirm" && <span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-green text-green-1">{widthdraw?.status}</span>
                                                                }
                                                                {
                                                                    widthdraw?.status === "Reject" && <span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-danger text-white">{widthdraw?.status}</span>
                                                                }
                                                            </td>
                                                            <td>{moment(widthdraw?.createdAt).format('DD MMM yyyy, hh:mm A')}</td>

                                                            <td>
                                                                <div className="btn-group">
                                                                    <button onClick={handleShow} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </button>

                                                                    <button onClick={() => DeleteWidthdraw(widthdraw?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>


                                                            <Modal show={show} onHide={handleClose}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Update Status</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="col-xl-12">
                                                                        <form method='post' onSubmit={(event) => UpdateWidthdrawStatusFunction(event, widthdraw?._id)} className="row x-gap-20 y-gap-20">
                                                                            <div className="col-12">
                                                                                <div className="form-input ">
                                                                                    <label className="lh-1 text-16 text-light-1">Status</label>
                                                                                    <select onChange={(event) => setStatus(event.target.value)} name='status' value={status} required className='form-control'>
                                                                                        <option defaultValue disabled value="">Choose</option>
                                                                                        <option value="Confirm">Confirm</option>
                                                                                        <option value="Reject">Reject</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-inline-block pt-30">

                                                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white w-100 ">
                                                                                    Update
                                                                                </button>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </tr>


                                                    ))
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminWidthdraws
