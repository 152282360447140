export const WIDTHDRAW_REQUEST = "WIDTHDRAW_REQUEST";
export const WIDTHDRAW_SUCCESS = "WIDTHDRAW_SUCCESS";
export const WIDTHDRAW_FAIL = "WIDTHDRAW_FAIL";

export const SINGLE_WIDTHDRAW_REQUEST = "SINGLE_WIDTHDRAW_REQUEST";
export const SINGLE_WIDTHDRAW_SUCCESS = "SINGLE_WIDTHDRAW_SUCCESS";
export const SINGLE_WIDTHDRAW_FAIL = "SINGLE_WIDTHDRAW_FAIL";

export const CREATE_WIDTHDRAW_REQUEST = "CREATE_WIDTHDRAW_REQUEST";
export const CREATE_WIDTHDRAW_SUCCESS = "CREATE_WIDTHDRAW_SUCCESS";
export const CREATE_WIDTHDRAW_RESET = "CREATE_WIDTHDRAW_RESET";
export const CREATE_WIDTHDRAW_FAIL = "CREATE_WIDTHDRAW_FAIL";

export const UPDATE_WIDTHDRAW_REQUEST = "UPDATE_WIDTHDRAW_REQUEST";
export const UPDATE_WIDTHDRAW_SUCCESS = "UPDATE_WIDTHDRAW_SUCCESS";
export const UPDATE_WIDTHDRAW_RESET = "UPDATE_WIDTHDRAW_RESET";
export const UPDATE_WIDTHDRAW_FAIL = "UPDATE_WIDTHDRAW_FAIL";

export const DELETE_WIDTHDRAW_REQUEST = "DELETE_WIDTHDRAW_REQUEST";
export const DELETE_WIDTHDRAW_SUCCESS = "DELETE_WIDTHDRAW_SUCCESS";
export const DELETE_WIDTHDRAW_RESET = "DELETE_WIDTHDRAW_RESET";
export const DELETE_WIDTHDRAW_FAIL = "DELETE_WIDTHDRAW_FAIL";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
