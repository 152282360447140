import {
    WEBSITE_PACKAGE_REQUEST,
    WEBSITE_PACKAGE_SUCCESS,
    WEBSITE_PACKAGE_FAIL,

    WEBSITE_SINGLE_PACKAGE_REQUEST,
    WEBSITE_SINGLE_PACKAGE_SUCCESS,
    WEBSITE_SINGLE_PACKAGE_FAIL,

    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAIL,

    CLEAR_ERRORS
} from '../constants/WebsiteConstant'

export const WebsiteReducer = (state = {}, action) => {
    switch (action.type) {

        case WEBSITE_PACKAGE_REQUEST:
        case WEBSITE_SINGLE_PACKAGE_REQUEST:
        case DELETE_ACCOUNT_REQUEST:
            return {
                loading: true,
            };




        case WEBSITE_PACKAGE_SUCCESS:
            return {
                loading: false,
                packages: action.payload.packages,
            };
        case DELETE_ACCOUNT_SUCCESS:
            return {
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
            };
        case WEBSITE_SINGLE_PACKAGE_SUCCESS:
            return {
                loading: false,
                package: action.payload.package,
            };


        case WEBSITE_PACKAGE_FAIL:
        case WEBSITE_SINGLE_PACKAGE_FAIL:
            return {
                loading: false,
                errors: action.payload,
            };
        case DELETE_ACCOUNT_FAIL:
            return {
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                errors: null,
            };
        default:
            return state;
    }
}