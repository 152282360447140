import React, { useEffect } from 'react'
import App from './layouts/App'
import MetaData from '../../components/MetaData'
import { useDispatch } from 'react-redux'
import { GetPackagesAction } from '../../redux/actions/Admin/PackageAction'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { GetBookingsAction } from '../../redux/actions/Admin/BookingAction'

const VendorDashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetPackagesAction());
        dispatch(GetBookingsAction());
    }, [dispatch]);

    const { loading, packages } = useSelector((state) => state.packages);
    const { bookings } = useSelector((state) => state.bookings);
    return (
        <App>
            <MetaData title={'Vendor - Dashboard'} />
            <div className="row y-gap-20 justify-between items-end pb-20 lg:pb-40 md:pb-32">
                <div className="col-auto">
                    <h1 className="text-30 lh-14 fw-500">Vendor - Dashboard</h1>
                </div>

                <div className="col-auto">

                </div>
            </div>

            {
                loading && loading ? <Loading /> :
                    <div className="row y-gap-30" style={{height : "100vh"}}>

              


                        <a href='/vendor/packages' className="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div className="py-15 px-30 custom_border_left_oreng custom_rounded bg-white custom_shadow">
                                <div className="row y-gap-20 justify-between items-center">
                                    <div className="col-md-6">
                                        <div className="fw-500 lh-14">Packages</div>
                                        <div className="text-30 lh-16 fw-600 mt-5 text_oreng">{packages?.length}</div>
                                        <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                    </div>

                                    <div className="col-md-6">
                                        <img src="/backend/img/dashboard/icons/arrow-2.png" alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </a>
                        
                        <a href='/vendor/bookings' className="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div className="py-15 px-30 custom_border_left_green custom_rounded bg-white custom_shadow">
                                <div className="row y-gap-20 justify-between items-center">
                                    <div className="col-md-6">
                                        <div className="fw-500 lh-14">Bookings</div>
                                        <div className="text-30 lh-16 fw-600 mt-5 text_green">{bookings?.length}</div>
                                        <div className="text-15 lh-14 text-light-1 mt-5 text_green">View <span><i className="fas fa-arrow-right"></i></span></div>
                                    </div>

                                    <div className="col-md-6">
                                        <img src="/backend/img/dashboard/icons/arrow-4.png" alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
            }



        </App>
    )
}

export default VendorDashboard
