import React, { useEffect, useState } from 'react'
import App from './layouts/App'
import MetaData from '../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import { PasswordUpdateAction, clearErrors } from '../../redux/actions/AuthAction'
import { UPDATE_PASSWORD_RESET } from '../../redux/constants/AuthConstant'

const VendorPassword = () => {
    const dispatch = useDispatch();

    const { loading, isUpdated, message, status } = useSelector((state) => state.profile);


    const [password, setPassword] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
    });

    const inpChnage = (event) => {
        setPassword({ ...password, [event.target.name]: event.target.value })
    }

    const updatePasswordFormSubmit = (event) => {
        event.preventDefault();

        if (password.new_password === password.confirm_password) {
            dispatch(PasswordUpdateAction(password.current_password, password.new_password))
        } else {
            toast.error("Password confirmation does not match...", { theme: "colored" })
        }


    }

    useEffect(() => {
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: UPDATE_PASSWORD_RESET })
            setPassword({
                current_password: "",
                new_password: "",
                confirm_password: "",
            });

        }

        if (status && status === 500) {
            toast.error(message, { theme: "colored" })
            dispatch(clearErrors)
        }
    }, [dispatch, isUpdated, message, status])

    return (
        <App>
            <MetaData title={'Vendor - Password'} />
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Profile</button>
                        </div>



                    </div>

                    <div className="tabs__content pt-30 js-tabs-content">
                        <div className="tabs__pane -tab-item-1 is-tab-el-active">


                            <div className="col-xl-12">

                                {
                                    loading ? <Loading /> :
                                        <form method='post' onSubmit={updatePasswordFormSubmit} className="row x-gap-20 y-gap-20">
                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Current Password</label>
                                                    <input type="text" required onChange={inpChnage} name='current_password' value={password.current_password} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">New Password</label>
                                                    <input type="text" onChange={inpChnage} name='new_password' value={password.new_password} required />
                                                </div>
                                            </div>



                                            <div className="col-6">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Confirm Password</label>
                                                    <input type="text" onChange={inpChnage} name='confirm_password' value={password.confirm_password} required />
                                                </div>
                                            </div>
                                            <div className="d-inline-block pt-30">

                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                    Update Password
                                                </button>

                                            </div>
                                        </form>
                                }

                            </div>


                        </div>


                    </div>
                </div>
            </div>



        </App>
    )
}

export default VendorPassword
