export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

export const SINGLE_CATEGORY_REQUEST = "SINGLE_CATEGORY_REQUEST";
export const SINGLE_CATEGORY_SUCCESS = "SINGLE_CATEGORY_SUCCESS";
export const SINGLE_CATEGORY_FAIL = "SINGLE_CATEGORY_FAIL";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_RESET = "CREATE_CATEGORY_RESET";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_RESET = "UPDATE_CATEGORY_RESET";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_RESET = "DELETE_CATEGORY_RESET";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
