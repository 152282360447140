import React from 'react'
import { useSelector } from 'react-redux';
import Loading from '../../../../components/Loading';

const Header = () => {
    const { loading, user } = useSelector((state) => state.auth);
    return (
        loading ? <Loading /> :
            <header data-add-bg="" className="header -dashboard bg-white js-header" data-x="header" data-x-toggle="is-menu-opened">
                <div className='d-flex align-items-center  w-100' >
                    <div className="-left-side">
                        <a href="/admin/dashboard" className="header-logo" data-x="header-logo" data-x-toggle="is-logo-dark">
                            <img src="/logo.png" alt="logo icon" />
                            <img src="/logo.png" alt="logo icon" />
                        </a>
                    </div>

                    <div className="row justify-between items-center w-100 pl-60 lg:pl-20">
                        <div className="col-auto">
                            <div className="d-flex items-center">
                                <button data-x-click="dashboard">
                                    <i className="icon-menu-2 text-20"></i>
                                </button>

                            </div>
                        </div>

                        <a href='/vendor/profile' className="col-auto">
                            <div className="d-flex items-center">

                                <div className="pl-10 pr-15">
                                    <img src={user?.image?.length > 0 ? user?.image : "/placeholder.jpg"} alt="image1" className="size-50 rounded-22 object-cover" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </header>
    )
}

export default Header
