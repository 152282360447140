import axios from 'axios'
import {
    PLAN_REQUEST,
    PLAN_SUCCESS,
    PLAN_FAIL,

    SINGLE_PLAN_REQUEST,
    SINGLE_PLAN_SUCCESS,
    SINGLE_PLAN_FAIL,

    CREATE_PLAN_REQUEST,
    CREATE_PLAN_SUCCESS,
    CREATE_PLAN_FAIL,

    UPDATE_PLAN_REQUEST,
    UPDATE_PLAN_SUCCESS,
    UPDATE_PLAN_FAIL,

    DELETE_PLAN_REQUEST,
    DELETE_PLAN_SUCCESS,
    DELETE_PLAN_FAIL,

    CLEAR_ERRORS
} from '../../constants/Admin/PlanConstant'
import { BaseURL } from '../../constants/BaseURL';

export const GetPlansAction = () => async (dispatch) => {
    try {
        dispatch({ type: PLAN_REQUEST });

        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/api/admin/plan`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: PLAN_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        dispatch({
            type: PLAN_FAIL,
            payload: error.response.data.message
        })
    }
};

export const SinglePlanAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_PLAN_REQUEST });

        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/api/admin/plan/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: SINGLE_PLAN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SINGLE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}


export const CreatePlanAction = (name) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PLAN_REQUEST });
        const bodyParameters = {
            name: name,
        };
        const token = localStorage.getItem('token');
        const { data } = await axios.post(`${BaseURL}/api/admin/plan/store`, bodyParameters, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: CREATE_PLAN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CREATE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}
export const CreatePlanItemAction = (id, title) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PLAN_REQUEST });
        const bodyParameters = {
            title: title,
        };
        const token = localStorage.getItem('token');
        const { data } = await axios.post(`${BaseURL}/api/admin/plan/include/store/${id}`, bodyParameters, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: CREATE_PLAN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CREATE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}

export const UpdatePlanAction = (id, name) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PLAN_REQUEST });

        const bodyParameters = {
            name: name,
        };

        const token = localStorage.getItem('token');
        const { data } = await axios.patch(`${BaseURL}/api/admin/plan/update/${id}`, bodyParameters, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: UPDATE_PLAN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: UPDATE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}
export const UpdatePlanItemAction = (id, plan_id, title) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PLAN_REQUEST });

        const bodyParameters = {
            title: title,
        };

        const token = localStorage.getItem('token');
        const { data } = await axios.patch(`${BaseURL}/api/admin/plan/include/update/${id}/${plan_id}`, bodyParameters, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: UPDATE_PLAN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: UPDATE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}
export const DeletePlanItemAction = (id, plan_id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PLAN_REQUEST });


        const token = localStorage.getItem('token');
        const { data } = await axios.delete(`${BaseURL}/api/admin/plan/include/delete/${id}/${plan_id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: DELETE_PLAN_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}





export const DeletePlanAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PLAN_REQUEST });

        const token = localStorage.getItem('token');
        const { data } = await axios.delete(`${BaseURL}/api/admin/plan/delete/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: DELETE_PLAN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_PLAN_FAIL,
            payload: error.response.data
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}