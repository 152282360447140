import React, { useEffect, useState } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { UPDATE_BANNER_RESET } from '../../../redux/constants/Admin/BannerConstant';
import { SingleBannerAction, UpdateBannerAction } from '../../../redux/actions/Admin/BannerAction';
import { useParams } from 'react-router-dom';

const AdminAdsUpdate = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { loading, isUpdated, message, banner } = useSelector((state) => state.banners);


    
    const [data, setData] = useState({
        title: "",
        link: ""
    })
    const [image, setImage] = useState();
    const [imagePreview, setImagePreview] = useState();
    const InpChnage = (event) => {
        if (event.target.name === "image") {
            setImage(event.target.files[0]);
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    }

    const UpdateAdsForm = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("link", data.link);
        formData.append("image", image);
        await dispatch(UpdateBannerAction(id, formData))

    }

    useEffect(() => {
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" })
            dispatch({ type: UPDATE_BANNER_RESET })
        }

        if (banner?._id !== id) {
            dispatch(SingleBannerAction(id))
        } else {
            setData({
                title: banner.title,
                link: banner.link,
            });
            setImagePreview(banner.image);
        }
    }, [dispatch, isUpdated, message, banner, id]);

    return (
        <App>
            <MetaData title={'Admin - Update Ads'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Update Ads</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/ads"><i className="icon-arrow-left text-12 text-white-1"></i> Back</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">
                                <div className="tabs__pane -tab-item-1 is-tab-el-active">


                                    <div className="col-xl-12">
                                        <form method='post' onSubmit={UpdateAdsForm} className="row x-gap-20 y-gap-20">
                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Title</label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.title} name='title' required />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Link</label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.link} name='link' required />
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Image</label>
                                                    <input className="form-control bg-light" type="file" onChange={InpChnage} name='image' />
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Old</label> <br />
                                                    <img src={imagePreview} alt="image1" className="size-40 rounded-2 object-cover" />
                                                </div>
                                            </div>

                                            <div className="d-inline-block pt-30">

                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                    Update
                                                </button>

                                            </div>
                                        </form>
                                    </div>


                                </div>


                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminAdsUpdate
