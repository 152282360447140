import React, { useEffect } from "react";
import App from "./layouts/App";
import MetaData from "../../components/MetaData";
import CommonBanner from "../../components/CommonBanner";

import { Link } from "react-router-dom";
// eslint-disable-next-line

import "./css/pricing-tables.css";
import "./css/color-1.css";
import { useDispatch, useSelector } from "react-redux";
import { GetPlansAction } from "../../redux/actions/Admin/PlanAction";
const Plan = () => {

  const dispatch = useDispatch();

  const { loading, plans, } = useSelector((state) => state.plan);

  useEffect(() => {
    dispatch(GetPlansAction())
  }, [dispatch]);

  return (

    <App>
      <MetaData title={"Plan"} />
      <CommonBanner heading={"Plan"} />
      {
        loading ? <h2>Loading...</h2> : (
          <section className="pp-section">
            <div className="container">
              <div className="section_heading_center">
                <h2>Plans</h2>
              </div>
              <div className="row">
                {
                  plans?.map((plan, index) => (
                    <div key={index} className="col-lg-3 col-md-6">
                      <ul className="Sava-wrapper">
                        <li data-type="monthly" className="is-visible">
                          <div className="plan-style-1">
                            <div className="plan-header">
                              <h3 className="plan-title">{plan?.name}</h3>
                              <div className="plan-price">
                                <p className="price">
                                  <span></span>{plan?.name}<span></span>
                                </p>
                              </div>
                              <span className="waves"></span>
                            </div>
                            <ul className="plan-list">
                              {
                                plan?.include?.map((inc, index) => (
                                  <li key={index}>
                                    <i className="far fa-check-circle"></i>{inc?.title}
                                    {/* <span className="tooltip-info">
                                      <span className="tooltip-hover-text">Every year</span>
                                      <span className="tooltiptext">unlimited</span>
                                    </span> */}
                                  </li>
                                ))
                              }
                            </ul>
                            <div className="plan-bottom">
                              <Link
                                className="plan-button"
                                to="https://app.aptopass.com"
                              >
                                Get Start
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))
                }
              </div>
            </div>
          </section>
        )
      }
    </App>
  );
};

export default Plan;
