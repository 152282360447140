export const BOOKING_REQUEST = "BOOKING_REQUEST";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAIL = "BOOKING_FAIL";

export const SINGLE_BOOKING_REQUEST = "SINGLE_BOOKING_REQUEST";
export const SINGLE_BOOKING_SUCCESS = "SINGLE_BOOKING_SUCCESS";
export const SINGLE_BOOKING_FAIL = "SINGLE_BOOKING_FAIL";

export const UPDATE_BOOKING_STATUS_REQUEST = "UPDATE_BOOKING_STATUS_REQUEST";
export const UPDATE_BOOKING_STATUS_SUCCESS = "UPDATE_BOOKING_STATUS_SUCCESS";
export const UPDATE_BOOKING_STATUS_RESET = "UPDATE_BOOKING_STATUS_RESET";
export const UPDATE_BOOKING_STATUS_FAIL = "UPDATE_BOOKING_STATUS_FAIL";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_RESET = "DELETE_BOOKING_RESET";
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
