import React, { useEffect } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { DeletePackageAction, GetPackagesAction } from '../../../redux/actions/Admin/PackageAction';
import { DELETE_PACKAGE_RESET } from '../../../redux/constants/Admin/PackageConstant';
import { truncate } from '../../../incude/truncate';

const AdminPackages = () => {
    const dispatch = useDispatch();

    const { loading, packages, message, isDeleted } = useSelector((state) => state.packages);

    const DeletePackage = (id) => {
        dispatch(DeletePackageAction(id));
        // if (window.confirm("Are you sure to delete ?")) {}
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_PACKAGE_RESET })
        }
        dispatch(GetPackagesAction())
    }, [dispatch, isDeleted, message]);

    return (
        <App>
            <MetaData title={'Admin - Packages'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Packages</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/package/create"><i className="icon-plus text-12 text-white-1"></i> Create</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Pacakge</th>
                                                    <th>Category</th>
                                                    <th>Locations</th>
                                                    <th>Days & Nights</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    packages?.map((pack) => (
                                                        <tr key={pack?._id}>
                                                            <td>
                                                                <img src={pack?.images[0] && pack?.images[0]?.image ? pack?.images[0]?.image : "/placeholder.jpg"} alt="image1" className="size-50 rounded-2 object-cover" />&nbsp;
                                                                {truncate(pack?.name, 30)}
                                                            </td>
                                                            <td>{pack?.category?.name}</td>
                                                            <td>
                                                                {
                                                                    pack?.location.length > 0 && (
                                                                        JSON.parse(pack?.location).map((inc, index) => (
                                                                            index === JSON.parse(pack?.location).length - 1 ? inc : `${inc}, ` 
                                                                        ))
                                                                    )
                                                                }
                                                            </td>
                                                            <td>{pack?.days} days & {pack?.nights} nights</td>
                                                            <td>
                                                                {
                                                                    pack?.status === "Disable" ?
                                                                        <span className='badge bg-danger'>{pack?.status}</span> :
                                                                        <span className='badge bg-success'>{pack?.status}</span>

                                                                }
                                                            </td>

                                                            <td>
                                                                <div className="btn-group">
                                                                    {/* <button className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-eye text-16 text-light-1"></i>
                                                                    </button> */}
                                                                    <a href={`/admin/package/update/${pack?._id}`} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </a>

                                                                    <button onClick={() => DeletePackage(pack?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminPackages
