import React, { useEffect, useState } from 'react';
import App from './layouts/App';
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { LoginFunction, clearErrors } from '../../redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';

const Login = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading : LoginLoading, status, message, isAuthenticated, user, token } = useSelector((state) => state.auth);
    const [login, setLogin] = useState({
        email: "",
        password: ""
    })

    const inpChnage = (event) => {
        setLogin({ ...login, [event.target.name]: event.target.value })
    }

    const LoginSubmit = (event) => {
        event.preventDefault();
        dispatch(LoginFunction(login.email, login.password));
    }

    let redirect = "";
    if (user && user.role === "Vendor") {
        redirect = "/vendor/dashboard";
    } else {
        redirect = "/admin/dashboard";
    }

    useEffect(() => {

        if (status && status === 501) {
            toast.error(message, { theme: "colored" });
            dispatch(clearErrors);
        }
        
        if (status && status === 200) {
            toast.success(message, { theme: "colored" });
            window.location.href = redirect;
        }


        if (isAuthenticated === true) {
            window.location.href = redirect;
        }

    }, [dispatch, navigate, status, message,user, isAuthenticated, redirect, token])

    return (
        
            <App>
                <MetaData title={'Login'} />
                <CommonBanner heading={'Login'} />
                <section id="common_author_area" className="section_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="common_author_boxed">
                                    <div className="common_author_heading">
                                        <h2>Login</h2>
                                    </div>
                                    <div className="common_author_form">
                                        <form method='POST' onSubmit={LoginSubmit} id="main_author_form">
                                            <div className="form-group">
                                                <input type="email" name="email" onChange={inpChnage} value={login.email} className="form-control" placeholder="Enter Email" autoFocus required />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name="password" onChange={inpChnage} value={login.password} className="form-control" placeholder="Enter password" required />
                                                {/* <a href="forgot-password.html">Forgot password?</a> */}
                                            </div>
                                            <div className="common_form_submit">
                                                <button disabled={LoginLoading} type='submit' className="btn btn_theme btn_md">{LoginLoading ? 'Loading...' : 'Log in'}</button>
                                            </div>

                                            <div className="have_acount_area">
                                                <p>Sign Up as Vendor? <a href="/vendor/register">Register now</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </App>
    )
}

export default Login
