import React from "react";
import CommonBanner from "../../components/CommonBanner";
import MetaData from "../../components/MetaData";
import App from "./layouts/App";

const Visa = () => {

  return (

    <App>
      <MetaData title={"Visa"} />
      <CommonBanner heading={"Comming Soon"} />
      
    </App>
  );
};

export default Visa;
