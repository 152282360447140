import React from 'react'
import MetaData from './MetaData'



const Loading = () => {
    return (
        <React.Fragment>
            <MetaData title="Loading..." />
            <div className="preloader">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Loading
