import React from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
const PrivacyPolicy = () => {
    return (
        <App>
            <MetaData title={'Privacy Policy'} />
            <CommonBanner heading={'Privacy Policy'} />

            <section id="tour_details_main" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="terms_service_content">
                                <div className="terms_item">
                                    <h4>Privacy Policy for AptoPass</h4>
                                    <p><b>Introduction</b></p>
                                    
                                    <p>
                                        AptoPass ("we," "us," or "our") is committed to protecting the privacy and personal information of our users ("you" or "your"). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you access or use the AptoPass mobile application ("App") and the AptoPass website ("Website") available at www.aptopass.com (collectively referred to as the "Services"). By accessing or using the Services, you consent to the practices described in this Privacy Policy.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>1. Information We Collect:</b></p>
                                    <p>
                                        1.1 Personal Information: We may collect personal information such as your name, email address, phone number, and payment details when you create an account, make bookings, or use certain features of the Services.
                                    </p>
                                    <p>
                                        1.2 Usage Data: We collect information about your interactions with the App and Website, such as the pages you visit, the features you use, and the search queries you make. This data helps us improve the Services and tailor your user experience.
                                    </p>
                                
                                    <p>
                                        1.3 Device Information: We automatically collect device-specific information, including your device type, operating system, unique device identifiers, and mobile network information when you use the App.
                                    </p>
                                    <p>
                                        1.4 Location Information: With your consent, we may collect and process information about your approximate location through GPS, Wi-Fi, or cellular network signals to provide location-based services.
                                    </p>
                                </div>

                                <div className="terms_item">
                                    <p><b>1. 2. How We Use Your Information:</b></p>
                                    <p>
                                        2.1 Provide and Improve Services: We use your information to facilitate bookings, process payments, and enhance the functionality and user experience of the Services.
                                    </p>
                                    <p>
                                        2.2 Personalization: We may use your information to personalize your search results, recommendations, and content to match your preferences and interests.
                                    </p>
                                
                                    <p>
                                        2.3 Communication: We may send you transactional emails, updates, and marketing communications related to the Services. You can opt-out of marketing communications at any time.
                                    </p>
                                    <p>
                                        2.4 Analytics: We analyze usage patterns and perform data analysis to improve and optimize our Services, troubleshoot issues, and monitor user engagement.
                                    </p>
                                    <p>
                                        2.5 Legal Compliance: We may use your information to comply with legal obligations, respond to legal requests, and protect our rights and the rights of others.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>3. How We Share Your Information:</b></p>
                                    <p>
                                        3.1 Service Providers: We may share your personal information with third-party service providers who assist us in providing the Services, such as payment processors, customer support, and marketing partners.
                                    </p>
                                    <p>
                                        3.2 Property Owners: If you make a booking through the Services, we may share your information with the respective property owner or manager to facilitate the booking process.
                                    </p>
                                
                                    <p>
                                        3.3 Aggregated Data: We may aggregate and anonymize your information to create statistical and analytical reports for internal purposes or to share with third parties for business or marketing purposes.
                                    </p>
                                    <p>
                                        3.4 Legal Requirements: We may disclose your information when required by law or in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>5. Data Security:</b></p>
                                    <p>
                                        5.1 We implement reasonable security measures to protect your information from unauthorized access, loss, misuse, or alteration. However, no method of transmission or electronic storage is entirely secure, and we cannot guarantee absolute security.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>6. Children's Privacy:</b></p>
                                    <p>
                                        6.1 The Services are not intended for use by children under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will promptly delete it.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>7. Your Choices:</b></p>
                                    <p>
                                        7.1 Account Information: You can review and update your account information by logging into your account settings.
                                    </p>
                                    <p>
                                        7.2 Marketing Communications: You can opt-out of receiving marketing communications by following the unsubscribe instructions provided in our emails or contacting us directly.
                                    </p>
                                    <p>
                                        7.3 Location Information: You can adjust your device settings to disable location services or withdraw your consent to location tracking.
                                    </p>
                                    <p>
                                        7.4 Cookies: You can manage cookie preferences through your browser settings.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>8. Third-Party Links and Services:</b></p>
                                    <p>
                                        8.1 The Services may contain links to third-party websites, services, or applications that are not controlled or operated by AptoPass. This Privacy Policy does not apply to third-party websites or services. We encourage you to review the privacy policies of those third parties before providing any personal information.
                                    </p>
                                </div>
                                <div className="terms_item">
                                    <p><b>9. Changes to this Privacy Policy:</b></p>
                                    <p>
                                        9.1 We may update this Privacy Policy from time to time. We will post any revisions on this page, and the "Effective Date" at the top of the policy will indicate when it was last updated.
                                    </p>
                                </div>

                                <div className="terms_item">
                                    <p><b>10. Contact Us:</b></p>
                                    <p>
                                        10.1 If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at aptopass@gmail.com <br/>
                                        By using AptoPass App and Website, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
                                        <br/>
                                        Last updated: 26/07/2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </App>
    )
}

export default PrivacyPolicy
