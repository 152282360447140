import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import MetaData from '../../../components/MetaData';
import App from '../layouts/App';

import { useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SinglePropertyAction, UpdatePropertyAction } from '../../../redux/actions/Admin/PropertyAction';
import { CREATE_PROPERTY_RESET } from '../../../redux/constants/Admin/PropertyConstant';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminPropertyUpdate = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { loading, message, property, isUpdated } = useSelector((state) => state.property);


    useEffect(() => {
        if (property?._id !== id) {
            dispatch(SinglePropertyAction(id))
        } else {
            setData({
                name: property?.name,
                rooms: property?.rooms,
                price: property?.price,
                deposit: property?.deposit,
                type: property?.type,
                key_money: property?.key_money,
                address: property?.address,
            });
            setPreviewImages(property?.galleries);
            setContent(property?.description);
            setFacilities(property?.facilities);
        }
    }, [dispatch, id, property])



    const [content, setContent] = useState('');
    const handleChange = (value) => {
        setContent(value);
    };

    const [facilities, setFacilities] = useState([]);
    const [data, setData] = useState({
        name: "",
        rooms: "",
        price: "",
        deposit: "",
        type: "",
        key_money: "",
        address: "",
    });

    const [images, setImages] = useState();
    const [previewImages, setPreviewImages] = useState();

    const InpChnage = (event) => {
        if (event.target.name === "images") {
            setImages(event.target.files)

            const files = Array.from(event.target.files);

            setPreviewImages([]);

            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setPreviewImages((old) => [...old, { image: reader.result }]);
                    }
                };
                reader.readAsDataURL(file);
            });
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    }

    const CreatePropertyForm = async (event) => {
        event.preventDefault();
        if (previewImages?.length > 10) {
            toast.error(`Mixumen limit is 10 and you have ${previewImages?.length} images...`, { theme: "colored" });
            return
        }
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("rooms", data.rooms);
        formData.append("price", data.price);
        formData.append("deposit", data.deposit);
        formData.append("type", data.type);
        formData.append("key_money", data.key_money);
        formData.append("address", data.address);
        formData.append("description", content);
        formData.append("facilities", facilities);
        if (images && images.length > 0) {
            Object.values(images).forEach(image => {
                formData.append("images", image);
            });
        }
        await dispatch(UpdatePropertyAction(id, formData))

    }

    useEffect(() => {
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" })
            dispatch({ type: CREATE_PROPERTY_RESET })

            setData({
                name: "",
                rooms: "",
                price: "",
                deposit: "",
                type: "",
                key_money: "",
                address: "",
            });
            setImages([]);
            setPreviewImages([]);
            setFacilities([]);
            window.location.href = '/admin/property';
        }

    }, [dispatch, isUpdated, message]);


    const animatedComponents = makeAnimated();

    const FacilitiesOptions = [
        { value: 'Swimming pool', label: 'Swimming pool' },
        { value: 'Bar', label: 'Bar' },
        { value: 'Restaurant', label: 'Restaurant' },
        { value: 'Pool Games', label: 'Pool Games' },
        { value: 'Kithchen', label: 'Kithchen' },
        { value: 'Elivator', label: 'Elivator' },
    ]
    const FacilitiesChange = (values) => {
        setFacilities(JSON.stringify(values?.map((val) => val.value)));
    };




    return (
        <App>
            <MetaData title={'Admin - Update Property'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Update Package</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/property"><i className="icon-arrow-left text-12 text-white-1"></i> Back</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">
                                <div className="tabs__pane -tab-item-1 is-tab-el-active">


                                    <div className="col-xl-12">
                                        <form method='post' onSubmit={CreatePropertyForm} className="row x-gap-20 y-gap-20">
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Name <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.name} name='name' required />
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Rooms <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.rooms} name='rooms' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Price <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.price} name='price' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Deposit <span className='text-danger'>*</span></label>
                                                    <select className="form-control bg-light" type="text" onChange={InpChnage} value={data.deposit} name='deposit' required >
                                                        <option value="Refundable">Refundable</option>
                                                        <option value="Non-Refundable">Non-Refundable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Type <span className='text-danger'>*</span></label>
                                                    <select className="form-control bg-light" type="text" onChange={InpChnage} value={data.type} name='type' required >
                                                        <option value="Lease">Lease</option>
                                                        <option value="Own">Own</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Key Money <span className='text-danger'>*</span></label>
                                                    <select className="form-control bg-light" type="text" onChange={InpChnage} value={data.key_money} name='key_money' required >
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Address <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.address} name='address' required />
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Facilities</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        const onChange={FacilitiesChange}
                                                        options={FacilitiesOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Galleries </label>
                                                    <input className="form-control bg-light" multiple type="file" onChange={InpChnage} name='images' />
                                                </div>
                                            </div>
                                            <div className="col-4"></div>
                                            <div className="col-4">
                                                Old Facilities
                                                {
                                                    property?.facilities.length > 0 && JSON.parse(property?.facilities).map((facility, index) => (

                                                        <li key={index}><i className="fas fa-circle"></i>&nbsp;{facility}</li>
                                                    ))
                                                }
                                            </div>







                                            {
                                                previewImages && (
                                                    <div className="row">
                                                        <p>Old Images</p>
                                                        {


                                                            previewImages && previewImages.map((file, index) => (
                                                                <div key={index} className='col-lg-3 mb-2' style={{ width: "15%" }}>
                                                                    <img className='img-thumbnail shadow-sm w-100 h-100' src={file?.image} alt="" />
                                                                </div>
                                                            ))



                                                        }
                                                    </div>

                                                )
                                            }

                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Description</label>
                                                    {/* <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.description} name='description' /> */}
                                                    <ReactQuill value={content} onChange={handleChange} />
                                                </div>
                                            </div>



                                            <div className="d-inline-block pt-30">

                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                    Update
                                                </button>

                                            </div>
                                        </form>
                                    </div>


                                </div>


                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminPropertyUpdate
