import React, { useEffect, useState } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { CreatePackageAction } from '../../../redux/actions/Admin/PackageAction';
import { CREATE_PACKAGE_RESET } from '../../../redux/constants/Admin/PackageConstant';
import { GetCategoriesAction } from '../../../redux/actions/Admin/CategoryAction';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const VendorPackageCreate = () => {
    const dispatch = useDispatch();

    const { loading: CategoryLoading, categories } = useSelector((state) => state.categories);
    const { loading, message, isCreated } = useSelector((state) => state.packages);


    useEffect(() => {
        dispatch(GetCategoriesAction())
    }, [dispatch]);

    const [including, setIncluding] = useState([]);
    const [excluding, setExcluding] = useState([]);
    const [data, setData] = useState({
        name: "",
        packageExpiryDate: "",
        category: "",
        location: "",
        days: "",
        nights: "",
        orignalPrice: "",
        downPrice: "0",
        description: "",
    });

    const [images, setImages] = useState();
    const [previewImages, setPreviewImages] = useState();

    const [content, setContent] = useState('');
    const [locations, setLocations] = useState([]);

    const handleChange = (value) => {
        setContent(value);
    };

    const InpChnage = (event) => {
        if (event.target.name === "images") {
            setImages(event.target.files)

            const files = Array.from(event.target.files);

            setPreviewImages([]);

            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setPreviewImages((old) => [...old, reader.result]);
                    }
                };
                reader.readAsDataURL(file);
            });
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    }

    const CreatePackageForm = async (event) => {
        event.preventDefault();
        if (previewImages?.length > 3) {
            toast.error(`Mixumen limit is 3 and you have ${previewImages?.length} images...`, { theme: "colored" });
            return
        }
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("packageExpiryDate", new Date(data.packageExpiryDate));
        formData.append("category", data.category);
        formData.append("location", locations);
        formData.append("days", data.days);
        formData.append("nights", data.nights);
        formData.append("orignalPrice", data.orignalPrice);
        formData.append("downPrice", data.downPrice);
        formData.append("including", including);
        formData.append("excluding", excluding);
        formData.append("description", content);
        Object.values(images).forEach(image => {
            formData.append("images", image);
        });
        await dispatch(CreatePackageAction(formData))

    }

    useEffect(() => {
        if (isCreated && isCreated === true) {
            toast.success(message, { theme: "colored" })
            dispatch({ type: CREATE_PACKAGE_RESET })

            setData({
                name: "",
                category: "",
                location: "",
                days: "",
                nights: "",
                orignalPrice: "",
                downPrice: "0",
                including: [],
                excluding: [],
                description: "",
            });
            window.location.href = "/vendor/packages";
        }

    }, [dispatch, isCreated, message]);


    const animatedComponents = makeAnimated();

    const IncludingOptions = [
        { value: '3 Star Property Accommodation  ', label: '3 Star Property Accommodation  ' },
        { value: '4 Star Property Accommodation', label: '4 Star Property Accommodation' },
        { value: '5 Star Property Accommodation', label: '5 Star Property Accommodation' },
        { value: 'Airport Transfer (Private)', label: 'Airport Transfer (Private)' },
        { value: 'Airport Transfer (Sharing)', label: 'Airport Transfer (Sharing)' },
        { value: 'Intra City Transfer', label: 'Intra City Transfer' },
        { value: 'Return Flight Tickets ', label: 'Return Flight Tickets ' },
        { value: 'Tours and Sight Seeing ', label: 'Tours and Sight Seeing ' },
        { value: 'Full Board Meal', label: 'Full Board Meal' },
        { value: 'Breakfast only', label: 'Breakfast only' },
        { value: 'Tourist Visa ', label: 'Tourist Visa ' },
        { value: 'Travel insurance', label: 'Travel insurance' },
        { value: 'Meet and Greet at Airport  ', label: 'Meet and Greet at Airport  ' },
        { value: 'Swimming pool ', label: 'Swimming pool ' },
        { value: 'Tour Guide during Tour ', label: 'Tour Guide during Tour ' },
    ]
    const IncludingChange = (values) => {
        setIncluding(JSON.stringify(values?.map((val) => val.value)));
    };

    const ExcludingOptions = [
        { value: '3 Star Property Accommodation  ', label: '3 Star Property Accommodation  ' },
        { value: '4 Star Property Accommodation', label: '4 Star Property Accommodation' },
        { value: '5 Star Property Accommodation', label: '5 Star Property Accommodation' },
        { value: 'Airport Transfer (Private)', label: 'Airport Transfer (Private)' },
        { value: 'Airport Transfer (Sharing)', label: 'Airport Transfer (Sharing)' },
        { value: 'Intra City Transfer', label: 'Intra City Transfer' },
        { value: 'Return Flight Tickets ', label: 'Return Flight Tickets ' },
        { value: 'Tours and Sight Seeing ', label: 'Tours and Sight Seeing ' },
        { value: 'Full Board Meal', label: 'Full Board Meal' },
        { value: 'Breakfast only', label: 'Breakfast only' },
        { value: 'Tourist Visa ', label: 'Tourist Visa ' },
        { value: 'Travel insurance', label: 'Travel insurance' },
        { value: 'Meet and Greet at Airport  ', label: 'Meet and Greet at Airport  ' },
        { value: 'Swimming pool ', label: 'Swimming pool ' },
        { value: 'Tour Guide during Tour ', label: 'Tour Guide during Tour ' },
    ]
    const ExcludingChange = (values) => {
        setExcluding(JSON.stringify(values?.map((val) => val.value)));
    };


    const LocationsOptions = [
        { value: 'Maldives', label: 'Maldives' },
        { value: 'Bangkok', label: 'Bangkok' },
        { value: 'Dubai', label: 'Dubai' },
        { value: 'Pattaya', label: 'Pattaya' },
        { value: 'Thailand', label: 'Thailand' },
    ]
    const LocationChange = (values) => {
        setLocations(JSON.stringify(values?.map((val) => val.value)));
    };


    return (
        <App>
            <MetaData title={'Vendor - Create Package'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Create Package</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/vendor/packages"><i className="icon-arrow-left text-12 text-white-1"></i> Back</a>
                        </div>

                    </div>

                    {
                        loading || CategoryLoading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">
                                <div className="tabs__pane -tab-item-1 is-tab-el-active">


                                    <div className="col-xl-12">
                                        <form method='post' onSubmit={CreatePackageForm} className="row x-gap-20 y-gap-20">
                                            <div className="col-8">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Name <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.name} name='name' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Expiry Date <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="date" onChange={InpChnage} value={data.packageExpiryDate} name='packageExpiryDate' required />
                                                </div>
                                            </div>
                                            {/* <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Location <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.location} name='location' required />
                                                </div>
                                            </div> */}
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Category <span className='text-danger'>*</span></label>
                                                    <select className='form-control bg-light' name="category" value={data.category} onChange={InpChnage} required>
                                                        <option disabled selected value={''}>Choose...</option>
                                                        {
                                                            categories?.map((category, index) => (
                                                                <option value={category?._id} key={index}>{category?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Days <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="number" onChange={InpChnage} value={data.days} name='days' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Nights <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="number" onChange={InpChnage} value={data.nights} name='nights' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Orignal Price <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="number" onChange={InpChnage} value={data.orignalPrice} name='orignalPrice' required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Discount Price <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" type="number" onChange={InpChnage} value={data.downPrice} name='downPrice' required />
                                                </div>
                                            </div>



                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Images <span className='text-danger'>*</span></label>
                                                    <input className="form-control bg-light" multiple type="file" onChange={InpChnage} name='images' required />
                                                </div>
                                            </div>


                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Including <span className='text-danger'>*</span></label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        const onChange={IncludingChange}
                                                        options={IncludingOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Excluding <span className='text-danger'>*</span></label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        const onChange={ExcludingChange}
                                                        options={ExcludingOptions}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-4">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Locations <span className='text-danger'>*</span></label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        const onChange={LocationChange}
                                                        options={LocationsOptions}
                                                    />
                                                </div>
                                            </div>









                                            {
                                                previewImages && (
                                                    <div className="row">
                                                        {


                                                            previewImages && previewImages.map((file, index) => (
                                                                <div key={index} className='col-lg-3 mb-2' style={{ width: "15%" }}>
                                                                    <img className='img-thumbnail shadow-sm w-100 h-100' src={file} alt="" />
                                                                </div>
                                                            ))



                                                        }
                                                    </div>

                                                )
                                            }


                                            <div className="col-12">
                                                <div className="form-input ">
                                                    <label className="lh-1 text-16 text-light-1">Description</label>
                                                    {/* <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.description} name='description' /> */}
                                                    <ReactQuill value={content} onChange={handleChange} />
                                                </div>
                                            </div>



                                            <div className="d-inline-block pt-30">

                                                <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                    Create
                                                </button>

                                            </div>
                                        </form>
                                    </div>


                                </div>


                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default VendorPackageCreate
