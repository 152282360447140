import React from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import { Link } from 'react-router-dom';
import CommonBanner from '../../components/CommonBanner';
const NotFound = () => {
    return (
        <App>
            <MetaData title={'404 - Page not found'} />
            <CommonBanner heading={'404 - Page Not Found'} />
            <section id="error_main" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="error_content text-center">
                                <img src="/assets/img/common/error.png" alt="img" />
                                <h2>Error 404 : page not found</h2>
                                <Link to="/" className="btn btn_theme btn_md">Back to home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </App>
    )
}

export default NotFound
