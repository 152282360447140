import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonBanner from '../../components/CommonBanner';
import MetaData from "../../components/MetaData";
import { GetPropertiesAction } from '../../redux/actions/Admin/PropertyAction';
import App from './layouts/App';
const Properties = () => {

    const dispatch = useDispatch();


    const { loading, properties, locations } = useSelector((state) => state.property);

    const [keyword, setKeyword] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [rooms, setRooms] = useState('');
    const [location, setLocation] = useState('');
    const [selectedFacilities, setSelectedFacilities] = useState([]);




    const fetchProperties = () => {
        const facilitiesString = selectedFacilities.join(',');
        dispatch(GetPropertiesAction({
            keyword,
            sortOrder,
            rooms,
            location,
            facilities: facilitiesString
        }));
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchProperties();
    }, [dispatch, keyword, sortOrder, rooms, location, selectedFacilities]);


    const SearchInputChange = (e) => {
        setKeyword(e.target.value);
    };
    const SortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };
    const RoomRangeChange = (e) => {
        setRooms(e.target.value);
    };

    const FacilitiesCheckboxChange = (facility) => {
        const updatedFacilities = selectedFacilities.includes(facility)
            ? selectedFacilities.filter(item => item !== facility)
            : [...selectedFacilities, facility];
        setSelectedFacilities(updatedFacilities);
    };
    const LocationChange = (value) => {
        setLocation(value);
    };







    return (
        <App>
            <MetaData title={'Properties'} />
            <CommonBanner heading={'Properties'} />


            {/* <Packages /> */}


            <section id="explore_area" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_center">
                                <h2>Our Awesome Properties</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3">
                            <div className="left_side_search_area">

                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Search by name <a className='text-primary float-end' href="/property">Clear Filter</a></h5>
                                    </div>
                                    <div className="name_search_form">
                                        <input type="text" className="form-control" value={keyword} onChange={SearchInputChange} placeholder="Search Propery..." />
                                        <button><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Filter by Price</h5>
                                    </div>
                                    <div className="name_search_form">
                                        <select type="text" value={sortOrder} onChange={SortOrderChange} className="form-control" placeholder="e.g Deluxe bus">
                                            <option value="HighToLow">High To Low</option>
                                            <option value="LowToLow">Low To Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Filter by Rooms</h5>
                                    </div>
                                    <div className="name_search_form">
                                        <select type="text" value={rooms} onChange={RoomRangeChange} className="form-control" placeholder="e.g Deluxe bus">
                                            <option value="0-10">0-10</option>
                                            <option value="10-20">10-20</option>
                                            <option value="20-30">20-30</option>
                                            <option value="30-50">30-50</option>
                                            <option value="50-70">50-70</option>
                                            <option value="70-100">70-100</option>
                                            <option value="100-1000">100-more</option>
                                        </select>
                                    </div>
                                </div>



                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Facilities</h5>
                                    </div>
                                    <div className="tour_search_type">
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Swimming pool" id="Swimming pool" checked={selectedFacilities.includes("Swimming pool")}
                                                onChange={() => FacilitiesCheckboxChange("Swimming pool")} />
                                            <label className="form-check-label" htmlFor="Swimming pool">
                                                <span className="area_flex_one">
                                                    <span>Swimming pool</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Bar" id="Bar" checked={selectedFacilities.includes("Bar")}
                                                onChange={() => FacilitiesCheckboxChange("Bar")} />
                                            <label className="form-check-label" htmlFor="Bar">
                                                <span className="area_flex_one">
                                                    <span>Bar</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Restaurant" id="Restaurant" checked={selectedFacilities.includes("Restaurant")}
                                                onChange={() => FacilitiesCheckboxChange("Restaurant")} />
                                            <label className="form-check-label" htmlFor="Restaurant">
                                                <span className="area_flex_one">
                                                    <span>Restaurant</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Pool Games" id="Pool Games" checked={selectedFacilities.includes("Pool Games")}
                                                onChange={() => FacilitiesCheckboxChange("Pool Games")} />
                                            <label className="form-check-label" htmlFor="Pool Games">
                                                <span className="area_flex_one">
                                                    <span>Pool Games</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Kithchen" id="Kithchen" checked={selectedFacilities.includes("Kithchen")}
                                                onChange={() => FacilitiesCheckboxChange("Kithchen")} />
                                            <label className="form-check-label" htmlFor="Kithchen">
                                                <span className="area_flex_one">
                                                    <span>Kithchen</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="form-check d-flex">
                                            <input className="form-check-input custom-check" type="checkbox" value="Elivator" id="Elivator" checked={selectedFacilities.includes("Elivator")}
                                                onChange={() => FacilitiesCheckboxChange("Elivator")} />
                                            <label className="form-check-label" htmlFor="Elivator">
                                                <span className="area_flex_one">
                                                    <span>Elivator</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="left_side_search_boxed">
                                    <div className="left_side_search_heading">
                                        <h5>Location</h5>
                                    </div>
                                    <div className="tour_search_type">
                                        {locations?.map((property, index) => (
                                            <div key={index} className="form-check d-flex">
                                                <input
                                                    className="form-check-input custom-check"
                                                    type="radio"
                                                    id={`Location${index}`}
                                                    name="location"
                                                    value={property?.address}
                                                    checked={location === property?.address}
                                                    onChange={() => LocationChange(property?.address)}
                                                />
                                                <label className="form-check-label" htmlFor={`Location${index}`}>
                                                    <span className="area_flex_one">
                                                        <span>{property?.address}</span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            {
                                loading ? <h5>Loading...</h5> : (
                                    <div className="row">
                                        {
                                            properties?.length > 0 ?
                                                properties?.map((property, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                                        <div className="theme_common_box_two">
                                                            <div className="theme_two_box_img">
                                                                <a href={`/property/${property?._id}`}><img src={property?.galleries[0]?.image} alt={property?.name} /></a>
                                                                <p><i className="fas fa-map-marker-alt"></i>{property?.address}</p>
                                                            </div>
                                                            <div className="theme_two_box_content">
                                                                <h4><a href={`/property/${property?._id}`}>{property?.name}</a></h4>
                                                                <p><span className="review_rating">Roms : {property?.rooms}</span> <span
                                                                    className="review_count">({property?.type})</span></p>
                                                                <h3>${property?.price}.00 <span>Price starts from</span></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                : (
                                                    <h5 className='text-center mt-5'>No Properties found...</h5>
                                                )
                                        }

                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>
            </section>
        </App>
    )
}

export default Properties
