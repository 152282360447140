import React, { useState, useEffect } from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { DeletAccountAction } from '../../redux/actions/WebsiteAction';
const DeleteAccount = () => {
    const dispatch = useDispatch();

    const { loading, message, status } = useSelector((state) => state.website);
    console.log(message);

    const [email, setEmail] = useState('');
    const DeleteAccountFunction = (event) => {
        event.preventDefault();
        if (!window.confirm(`Are you sure you want to delete your account?`)) {
            return;
        }
        dispatch(DeletAccountAction(email));
    }


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const emailValue = queryParams.get('email');
        if (emailValue) {
            setEmail(emailValue);
        }
    }, []);

    const [isChecked, setIsChecked] = useState(false);
    const HandleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {

        if (status && status === 200) {
            toast.success(message, { theme: "colored" })
            setEmail('');
        }
        if (status && status === 500) {
            toast.error(message, { theme: "colored" })
        }
    }, [status, message]);
    return (
        <App>
            <MetaData title={'Delete Account'} />
            <CommonBanner heading={'Delete Account'} />
            <section id="vendor_form_area" className="section_padding_bottom">
                <div className="container">
                    <div className="row mt-5">

                        <div className="col-lg-12 mt-5">
                            <div className="vendor_form_heading mt-5 pt-5">
                                <h2>Delete My Account</h2>
                                <p>You have to understand that account can not be recovered after 30 days request done...</p>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form onSubmit={DeleteAccountFunction}>


                                <div className="vendor_form">
                                    <div className="tour_booking_form_box">

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input type="email" className="form-control bg_input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email*" required autoFocus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="pt-4">
                                        <div className="form-check d-flex flex-row" style={{ marginBottom: 20 }}>

                                            <input className="form-check-input" style={{ width: 16, marginRight: 15 }} type="checkbox" id="Agree" onChange={HandleCheckboxChange} />
                                            <label className="form-check-label" htmlFor="Agree">
                                                I agree and understand that account can not be recovered after 30 days request done!
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                {loading}
                                <button disabled={!isChecked} type="submit" className="btn btn_theme btn_md">Delete My Account</button>
                            </form>
                        </div>
                        <div className="col-lg-4">
                            <div className="vendor_img">
                                <img src="/assets/img/common/vendor.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </App>
    )
}

export default DeleteAccount
