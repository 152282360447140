export const WEBSITE_PACKAGE_REQUEST = "WEBSITE_PACKAGE_REQUEST";
export const WEBSITE_PACKAGE_SUCCESS = "WEBSITE_PACKAGE_SUCCESS";
export const WEBSITE_PACKAGE_FAIL = "WEBSITE_PACKAGE_FAIL";

export const WEBSITE_SINGLE_PACKAGE_REQUEST = "WEBSITE_SINGLE_PACKAGE_REQUEST";
export const WEBSITE_SINGLE_PACKAGE_SUCCESS = "WEBSITE_SINGLE_PACKAGE_SUCCESS";
export const WEBSITE_SINGLE_PACKAGE_FAIL = "WEBSITE_SINGLE_PACKAGE_FAIL";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";
export const DELETE_ACCOUNT_RESET = "DELETE_ACCOUNT_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";