import React, { useEffect, useState } from 'react'
import App from './layouts/App'
import MetaData from '../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { AuthUser, ProfileUpdateAction } from '../../redux/actions/AuthAction'
import { toast } from 'react-toastify'
import { UPDATE_PROFILE_RESET } from '../../redux/constants/AuthConstant'

const VendorProfile = () => {
    const dispatch = useDispatch();

    const { loading, user } = useSelector((state) => state.auth);
    const { loading : updateLoading, isUpdated, message } = useSelector((state) => state.profile);

    const [data, setData] = useState({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
    });

    const [image, setImage] = useState();

    const InpChnage = (event) => {
        if (event.target.name === "image") {
            setImage(event.target.files[0]);
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    }

    const UpdateProfileFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("image", image);
        await dispatch(ProfileUpdateAction(formData))

    }

    useEffect(() => {
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: UPDATE_PROFILE_RESET })
            dispatch(AuthUser());
            window.location.href = "/vendor/profile";
        }
    }, [dispatch, isUpdated, message])
    return (
        loading || updateLoading ? <Loading /> :
            <App>
                <MetaData title={'Vendor - Profile'} />
                <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="tabs -underline-2 js-tabs">
                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                            <div className="col-auto">
                                <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Profile</button>
                            </div>



                        </div>

                        <div className="tabs__content pt-30 js-tabs-content">
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">


                                <div className="col-xl-12">
                                    <form method='post' onSubmit={UpdateProfileFormSubmit} className="row x-gap-20 y-gap-20">
                                        <div className="col-6">
                                            <div className="form-input ">
                                                <label className="lh-1 text-16 text-light-1">Name</label>
                                                <input type="text" onChange={InpChnage} value={data.name} name='name' required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-input ">
                                                <label className="lh-1 text-16 text-light-1">Email</label>
                                                <input type="email" onChange={InpChnage} value={data.email} name='email' required />
                                            </div>
                                        </div>



                                        <div className="col-6">
                                            <div className="form-input ">
                                                <label className="lh-1 text-16 text-light-1">Phone</label>
                                                <input type="text" onChange={InpChnage} value={data.phone} name='phone' required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-input ">
                                                <label className="lh-1 text-16 text-light-1">image</label>
                                                <input type="file" onChange={InpChnage} name='image' />
                                            </div>
                                        </div>
                                        <div className="d-inline-block pt-30">

                                            <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                                Update Profile
                                            </button>

                                        </div>
                                    </form>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>



            </App>
    )
}

export default VendorProfile
