import React from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import { Link } from 'react-router-dom';
import Packages from '../../components/Packages';
const Home = () => {
    
    return (
        <App>
            <MetaData title={'Home'} />
            <section id="home_one_banner" style={{ paddingTop: 245, paddingRight: 0, paddingBottom: 200, paddingLeft: 0 }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="banner_one_text">
                                <h1>Invest in Tourism Industry</h1>
                                <div className="home_two_button btn-animation text-center" style={{ paddingTop: 10 }}>
                                    <Link to="/packages" className="btn btn_theme_white btn_md" style={{ paddingTop: 3, paddingRight: 18, paddingBottom: 3, paddingLeft: 18 }}>Thailand</Link>
                                    <Link to="/packages" className="btn btn_theme_transparent btn_md" style={{ paddingTop: 3, paddingRight: 18, paddingBottom: 3, paddingLeft: 18 }}>Vietnam</Link>
                                    <Link to="/packages" className="btn btn_theme_transparent btn_md" style={{ paddingTop: 3, paddingRight: 18, paddingBottom: 3, paddingLeft: 18 }}>Malasiya</Link>
                                </div>
                                <h3>Free Travel + Earn Money</h3>
                                <p className='text-white'>Join Apto Pass and get free travel to international destinations with your family and earn assure Aptopass investment plan.</p>

                                <div className="home_two_button btn-animation" style={{ paddingTop: 10 }}>
                                    <Link to="#" className="btn btn_navber btn_md" style={{ paddingTop: 3, paddingRight: 18, paddingBottom: 3, paddingLeft: 18 }}>Starting from $6000 USD</Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>


            <section id="about_us_top" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_us_left">
                                <h5>About us</h5>
                                <h2>We Are The World Best Travel Agency</h2>
                                <p>AptoPass is an online marketplace and hospitality platform that specializes in short- and long-term homestays and hotels. As a broker, AptoPass connects travelers with a wide range of accommodation options, facilitating bookings and ensuring a seamless experience for both guests and property owners. </p>
                                <p>The core business of AptoPass revolves around providing a comprehensive platform for travelers to search...</p>
                                <a href="/about" className="btn btn_theme btn_md">Read More</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_us_right">
                                <img src="/assets/img/common/abour_right.png" alt="img" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="about_service_offer" className="section_padding_bottom">
                <div className="container">
                    <div className="section_heading_center"><h2>Why Choose AptoPass</h2></div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/world.png" alt="img" />
                                <h5><a href="#!">Invest in Hotels & Apartments</a></h5>
                                <p>Direct investment in Hotel industry and get Bussiness Visa from travel.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Assure Return Plan</a></h5>
                                <p>Earn 11% Assure return per annual without any worry.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/persentis.png" alt="img" />
                                <h5><a href="#!">Zero Risk Policy</a></h5>
                                <p>Zero Risk policy protect your from any finanical loss.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="counter_four_area" className="" style={{ padding: 0 }}>
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="countre_four_item">
                                <h3>750+</h3>
                                <h5>Properties</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="countre_four_item">
                                <h3>900+</h3>
                                <h5>Happy Clients</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="countre_four_item">
                                <h3>11+</h3>
                                <h5>Countries</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />

            <section id="about_service_offer" className="">
                <div className="container">
                    <div className="section_heading_center"><h2>Why Invest</h2></div>
                    <div className="row">

                        <div className="col">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Assure Return</a></h5>
                                <p>Assured Return minimum 10% & Upto 30%.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Free Accommodation</a></h5>
                                <p>Free Accommodation Intentionally &nbsp;&nbsp;&nbsp; Every Year.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Business Visa</a></h5>
                                <p>Option for Direct Investment arid Business Visa.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Hassle free travel</a></h5>
                                <p>Hassle free travel, Visa free or Low Visa Requirements.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about_service_boxed">
                                <img src="/assets/img/icon/star.png" alt="img" />
                                <h5><a href="#!">Free Travel</a></h5>
                                <p>Free Travel & Accommodation with Family.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section id="go_beyond_area" className="section_padding_top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="heading_left_area">
                                <h2>Go beyond your <span>imagination</span></h2>
                                <h5>Discover your ideal experience with us</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <Link to="#">
                                    <img src="/assets/img/imagination/imagination1.png" alt="img" />
                                </Link>
                                <h3><Link to="#">7% Discount for all <span>Airlines</span></Link></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <Link to="#">
                                    <img src="/assets/img/imagination/imagination2.png" alt="img" />
                                </Link>
                                <h3><Link to="#!">Travel around<span>the world</span></Link></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <Link to="#">
                                    <img src="/assets/img/imagination/imagination3.png" alt="img" />
                                </Link>
                                <h3><Link to="#">Luxury resorts<span>top deals</span></Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="top_destinations" className="section_padding_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_center">
                                <h2>Top destinations</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="destinations_content_box img_animation">
                                <img src="/assets/img/destination/big-img.png" alt="img" />
                                <div className="destinations_content_inner">
                                    <h2>Up to</h2>
                                    <div className="destinations_big_offer">
                                        <h1>50</h1>
                                        <h6><span>%</span> <span>Off</span></h6>
                                    </div>
                                    <h2>Holiday packages</h2>
                                    <Link to="#" className="btn btn_theme btn_md">Book now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination1.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">China</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination2.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">Darjeeling</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination3.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">Malaysia</Link></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination4.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">Gangtok</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination5.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">Thailand</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination6.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">Australia</Link></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination7.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">London</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box img_animation">
                                        <Link to="#">
                                            <img src="/assets/img/destination/destination8.png" alt="img" />
                                        </Link>
                                        <div className="destinations_content_inner">
                                            <h3><Link to="#">USA</Link></h3>
                                        </div>
                                    </div>
                                    <div className="destinations_content_box">
                                        <Link to="#" className="btn btn_theme btn_md w-100">View all</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Packages />
        </App>
    )
}

export default Home
