import React, { useEffect, useState } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { UpdatePlanAction, UpdateUserStatusAction, deleteAdminUser, getAdminUsers } from '../../../redux/actions/Admin/UserAction';
import { toast } from 'react-toastify';
import { DELETE_USER_RESET, STATUS_USER_RESET } from '../../../redux/constants/Admin/UserConstant';
import Loading from '../../../components/Loading';
import moment from 'moment';
import * as XLSX from 'xlsx'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AdminUsers = () => {
    const dispatch = useDispatch();

    const { loading, users, message, isDeleted, isUpdated } = useSelector((state) => state.users);

    const DeleteUser = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(deleteAdminUser(id));
        }
    }





    const UpdateUserStatus = (id, status) => {

        if (window.confirm("Are you sure to update ?")) {
            dispatch(UpdateUserStatusAction(id, status));
        }
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_USER_RESET })
        }
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: STATUS_USER_RESET })
            setShow(false);
        }
        dispatch(getAdminUsers())
    }, [dispatch, isDeleted, isUpdated, message]);


    const HandelExcel = () => {

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(users);

        XLSX.utils.book_append_sheet(wb, ws, "Users")
        XLSX.writeFile(wb, "Users.xlsx")
    }




    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState(null);
    
    const handleClose = () => {
        setUserId(null);
        setShow(false)
    };
    const handleShow = (user) => {
        setUserId(user?._id);
        setData({
            investment_plan: user?.investment_plan,
            time_period: user?.time_period,
            interest_rate: user?.interest_rate,
            amount_on_maturity: user?.amount_on_maturity,
            start_date: user?.start_date,
            maturity_date: user?.maturity_date,
        })
        setShow(true)
    };
    
    

    const [data, setData] = useState({
        investment_plan: "",
        time_period: "",
        interest_rate: "",
        amount_on_maturity: "",
        start_date: "",
        maturity_date: "",
    });


    const InpChnage = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }

    const UpdatePlanForm = async (event) => {
        event.preventDefault();
        console.log(data);
        await dispatch(UpdatePlanAction(userId, data))

    }
    return (
        <App>
            <MetaData title={'Admin - Users'} />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method='post' onSubmit={UpdatePlanForm} className="row x-gap-20 y-gap-20">
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Investment Plan</label>
                                <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.investment_plan} name='investment_plan' required />
                            </div>
                        </div>
                        
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Time Period</label>
                                <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.time_period} name='time_period' required />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Interest Rate</label>
                                <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.interest_rate} name='interest_rate' required />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Amount on Maturity</label>
                                <input className="form-control bg-light" type="text" onChange={InpChnage} value={data.amount_on_maturity} name='amount_on_maturity' required />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Start Date</label>
                                <input className="form-control bg-light" type="date" onChange={InpChnage} value={data.start_date} name='start_date' required />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-input ">
                                <label className="lh-1 text-16 text-light-1">Maturity Date</label>
                                <input className="form-control bg-light" type="date" onChange={InpChnage} value={data.maturity_date} name='maturity_date' required />
                            </div>
                        </div>

                        <div className="d-inline-block pt-30">

                            <button type='submit' className="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Update
                            </button>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Users</button>
                            <button onClick={HandelExcel} type='button' className='btn btn-sm rounded-4 bg-blue-1 text-white'><i className="icon-info text-12 text-white-1"></i> Export Excel</button>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>User</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Cash Amount</th>
                                                    <th>Status</th>
                                                    <th>RegisterAt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    users?.map((user) => (
                                                        <tr key={user?._id}>
                                                            <td>
                                                                <img src={user?.image?.length > 0 ? user?.image : "/placeholder.jpg"} alt="image1" className="size-40 rounded-2 object-cover" />&nbsp;
                                                                {user?.name}
                                                            </td>
                                                            <td>{user?.email}</td>
                                                            <td>{user?.phone}</td>
                                                            <td>${user?.rewardPoints.toFixed(2) / 100}</td>
                                                            <td>
                                                                {
                                                                    user?.status === "Active" ? (
                                                                        <span className='badge bg-success'>{user?.status}</span>
                                                                    ) : (
                                                                        <span className='badge bg-danger'>{user?.status}</span>

                                                                    )
                                                                }
                                                            </td>
                                                            <td>{moment(user?.createAt).format('DD MMM yyyy, hh:mm A')}</td>

                                                            <td>
                                                                <div className="btn-group">
                                                                    <button onClick={() => handleShow(user)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="fa fa-credit-card text-16 text-light-1"></i>
                                                                    </button>
                                                                    <button onClick={() => UpdateUserStatus(user?._id, user?.status === "Active" ? "InActive" : "Active")} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-eye text-16 text-light-1"></i>
                                                                    </button>

                                                                    <button onClick={() => DeleteUser(user?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    ))
                                                }






                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminUsers
