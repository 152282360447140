import axios from 'axios';
import {
    CLEAR_ERRORS,
    CREATE_PROPERTY_FAIL,
    CREATE_PROPERTY_REQUEST,
    CREATE_PROPERTY_SUCCESS,
    DELETE_PROPERTY_FAIL,
    DELETE_PROPERTY_REQUEST,
    DELETE_PROPERTY_SUCCESS,
    PROPERTY_FAIL,
    PROPERTY_REQUEST,
    PROPERTY_SUCCESS,

    PACKAGES_REQUEST,
    PACKAGES_SUCCESS,
    PACKAGES_FAIL,

    SINGLE_PROPERTY_FAIL,
    SINGLE_PROPERTY_REQUEST,
    SINGLE_PROPERTY_SUCCESS,
    UPDATE_PROPERTY_FAIL,
    UPDATE_PROPERTY_REQUEST,
    UPDATE_PROPERTY_SUCCESS
} from '../../constants/Admin/PropertyConstant';
import { BaseURL } from '../../constants/BaseURL';
export const GetPropertiesAction = (params = {}) => async (dispatch) => {
    try {
        dispatch({ type: PROPERTY_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/api/admin/property`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            params: params
        });
        dispatch({
            type: PROPERTY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: PROPERTY_FAIL,
            payload: error.response.data.message
        });
    }
};

export const GetPackagesAction = (params = {}) => async (dispatch) => {
    try {
        dispatch({ type: PACKAGES_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/api/admin/property/packages`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            params: params
        });
        dispatch({
            type: PACKAGES_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: PACKAGES_FAIL,
            payload: error.response.data.message
        });
    }
};

export const SinglePropertyAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_PROPERTY_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/api/admin/property/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: SINGLE_PROPERTY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SINGLE_PROPERTY_FAIL,
            payload: error.response.data
        })
    }
}


export const CreatePropertyAction = (formData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PROPERTY_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.post(`${BaseURL}/api/${role}/property/store`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${token}`
            }
        });

        dispatch({
            type: CREATE_PROPERTY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_PROPERTY_FAIL,
            payload: error.message,
        })

    }
}


export const UpdatePropertyAction = (id, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROPERTY_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.patch(`${BaseURL}/api/${role}/property/update/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${token}`
            }
        });

        dispatch({
            type: UPDATE_PROPERTY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PROPERTY_FAIL,
            payload: error.message,
        })

    }
}




export const DeletePropertyAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROPERTY_REQUEST });
        const role = localStorage.getItem("role");
        const token = localStorage.getItem('token');
        const { data } = await axios.delete(`${BaseURL}/api/${role}/property/delete/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        dispatch({
            type: DELETE_PROPERTY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_PROPERTY_FAIL,
            payload: error.response.data
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}