import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import Loading from '../../../components/Loading';
import MetaData from '../../../components/MetaData';
import { UpdateUserStatusAction, deleteAdminUser, getAdminVendors } from '../../../redux/actions/Admin/UserAction';
import { DELETE_USER_RESET, STATUS_USER_RESET } from '../../../redux/constants/Admin/UserConstant';
import App from '../layouts/App';


const AdminVendors = () => {
    const dispatch = useDispatch();

    const { loading, users, message, isDeleted, isUpdated } = useSelector((state) => state.users);

    const DeleteUser = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(deleteAdminUser(id));
        }
    }





    const UpdateUserStatus = (id, status) => {

        if (window.confirm("Are you sure to update ?")) {
            dispatch(UpdateUserStatusAction(id, status));
        }
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_USER_RESET })
        }
        if (isUpdated && isUpdated === true) {
            toast.success(message, { theme: "colored" });
            dispatch({ type: STATUS_USER_RESET })
        }
        dispatch(getAdminVendors())
    }, [dispatch, isDeleted, isUpdated, message]);


    const HandelExcel = () => {

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(users);

        XLSX.utils.book_append_sheet(wb, ws, "Vendors")
        XLSX.writeFile(wb, "Vendors.xlsx")
    }





 
    return (
        <App>
            <MetaData title={'Admin - Vendors'} />
            

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Vendors</button>
                            {/* <button onClick={HandelExcel} type='button' className='btn btn-sm rounded-4 bg-blue-1 text-white'><i className="icon-info text-12 text-white-1"></i> Export Excel</button> */}
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Vendor</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Status</th>
                                                    <th>RegisterAt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    users?.map((user) => (
                                                        <tr key={user?._id}>
                                                            <td>
                                                                <img src={user?.image?.length > 0 ? user?.image : "/placeholder.jpg"} alt="image1" className="size-40 rounded-2 object-cover" />&nbsp;
                                                                {user?.name}
                                                            </td>
                                                            <td>{user?.email}</td>
                                                            <td>{user?.phone}</td>
                                                            <td>
                                                                {
                                                                    user?.status === "Active" ? (
                                                                        <span className='badge bg-success'>{user?.status}</span>
                                                                    ) : (
                                                                        <span className='badge bg-danger'>{user?.status}</span>

                                                                    )
                                                                }
                                                            </td>
                                                            <td>{moment(user?.createAt).format('DD MMM yyyy, hh:mm A')}</td>

                                                            <td>
                                                                <div className="btn-group">
                                                                  
                                                                    <button onClick={() => UpdateUserStatus(user?._id, user?.status === "Active" ? "InActive" : "Active")} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-eye text-16 text-light-1"></i>
                                                                    </button>

                                                                    <button onClick={() => DeleteUser(user?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    ))
                                                }






                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminVendors
