export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_RESET = "LOGIN_RESET";

export const VENDOR_REGISTER_REQUEST = "VENDOR_REGISTER_REQUEST";
export const VENDOR_REGISTER_SUCCESS = "VENDOR_REGISTER_SUCCESS";
export const VENDOR_REGISTER_FAIL = "VENDOR_REGISTER_FAIL";
export const VENDOR_REGISTER_RESET = "VENDOR_REGISTER_RESET";

export const VENDOR_VERIFY_REQUEST = "VENDOR_VERIFY_REQUEST";
export const VENDOR_VERIFY_SUCCESS = "VENDOR_VERIFY_SUCCESS";
export const VENDOR_VERIFY_FAIL = "VENDOR_VERIFY_FAIL";
export const VENDOR_VERIFY_RESET = "VENDOR_VERIFY_RESET";

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAIL = "AUTH_USER_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";


export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";


export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";