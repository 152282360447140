import React from 'react'
import MetaData from '../../../components/MetaData'
import Header from './include/Header';
import SideBar from './include/SideBar';

const App = ({ children }) => {
    return (
        <React.Fragment>
            <MetaData title={'Admin - Dashbaord'} />

            <div className="header-margin"></div>
            <Header />
            <div className="dashboard" data-x="dashboard" data-x-toggle="-is-sidebar-open">
                <SideBar />
                <div className="dashboard__main">
                    <div className="dashboard__content">
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default App
