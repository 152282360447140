export const BANNER_REQUEST = "BANNER_REQUEST";
export const BANNER_SUCCESS = "BANNER_SUCCESS";
export const BANNER_FAIL = "BANNER_FAIL";

export const SINGLE_BANNER_REQUEST = "SINGLE_BANNER_REQUEST";
export const SINGLE_BANNER_SUCCESS = "SINGLE_BANNER_SUCCESS";
export const SINGLE_BANNER_FAIL = "SINGLE_BANNER_FAIL";

export const CREATE_BANNER_REQUEST = "CREATE_BANNER_REQUEST";
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS";
export const CREATE_BANNER_RESET = "CREATE_BANNER_RESET";
export const CREATE_BANNER_FAIL = "CREATE_BANNER_FAIL";

export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_RESET = "UPDATE_BANNER_RESET";
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_RESET = "DELETE_BANNER_RESET";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
