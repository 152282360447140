import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Loading from '../../../../components/Loading';
import { GetWebsitePackagesAction } from '../../../../redux/actions/WebsiteAction';

const Footer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetWebsitePackagesAction());
    }, [dispatch]);


    const { loading, packages } = useSelector((state) => state.website);
    return (
        loading ? <Loading /> :
            <React.Fragment>
                <section id="cta_area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="cta_left">
                                    <div className="cta_icon">
                                        <img src="/assets/img/common/email.png" alt="icon" />
                                    </div>
                                    <div className="cta_content">
                                        <h4>Get the latest news and offers</h4>
                                        <h2>Subscribe to our newsletter</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="cat_form">
                                    <form id="cta_form_wrappper">
                                        <div className="input-group"><input type="text" className="form-control"
                                            placeholder="Enter your mail address" /><button className="btn btn_theme btn_md"
                                                type="button">Subscribe</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer id="footer_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="footer_heading_area">
                                    <h5>Need any help?</h5>
                                </div>
                                <div className="footer_first_area">
                                    <div className="footer_inquery_area">
                                        <h5>Call 24/7 for any help</h5>
                                        <h3> <Link to="tel:+91 82646 53729">+91 82646 53729</Link></h3>
                                    </div>
                                    <div className="footer_inquery_area">
                                        <h5>Mail to our support team</h5>
                                        <h3> <Link to="mailto:aptopass@gmail.com">aptopass@gmail.com</Link></h3>
                                    </div>
                                    <div className="footer_inquery_area">
                                        <h5>Follow us on</h5>
                                        <ul className="soical_icon_footer">
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter-square"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="footer_heading_area">
                                    <h5>Company</h5>
                                </div>
                                <div className="footer_link_area">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/vendor/register">Supplier Register</Link></li>
                                        <li><Link to="/packages">Packages</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/term-and-conditions">Term and conditions</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="footer_heading_area">
                                    <h5>Top 5 Packages</h5>
                                </div>
                                <div className="footer_link_area">
                                    <ul>
                                        {
                                            packages?.slice(0, 5).map((pack) => (

                                                <li key={pack?._id}><Link to="#">{pack?.name}</Link></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>

    )
}

export default Footer
