import {
    PROPERTY_REQUEST,
    PROPERTY_SUCCESS,
    PROPERTY_FAIL,

    PACKAGES_REQUEST,
    PACKAGES_SUCCESS,
    PACKAGES_FAIL,

    SINGLE_PROPERTY_REQUEST,
    SINGLE_PROPERTY_SUCCESS,
    SINGLE_PROPERTY_FAIL,

    CREATE_PROPERTY_REQUEST,
    CREATE_PROPERTY_SUCCESS,
    CREATE_PROPERTY_RESET,
    CREATE_PROPERTY_FAIL,

    UPDATE_PROPERTY_REQUEST,
    UPDATE_PROPERTY_SUCCESS,
    UPDATE_PROPERTY_RESET,
    UPDATE_PROPERTY_FAIL,

    DELETE_PROPERTY_REQUEST,
    DELETE_PROPERTY_SUCCESS,
    DELETE_PROPERTY_RESET,
    DELETE_PROPERTY_FAIL,


    CLEAR_ERRORS
} from '../../constants/Admin/PropertyConstant'

export const PropertyReducer = (state = {}, action) => {
    switch (action.type) {
        case PROPERTY_REQUEST:
        case SINGLE_PROPERTY_REQUEST:
        case CREATE_PROPERTY_REQUEST:
        case UPDATE_PROPERTY_REQUEST:
        case DELETE_PROPERTY_REQUEST:
        case PACKAGES_REQUEST:
            return {
                loading: true,
            };




        case PROPERTY_SUCCESS:
            return {
                loading: false,
                properties: action.payload.properties,
                locations: action.payload.locations,
            };
        case PACKAGES_SUCCESS:
            return {
                loading: false,
                packages: action.payload.packages,
            };
        case SINGLE_PROPERTY_SUCCESS:
            return {
                loading: false,
                property: action.payload.property,
            };
        case CREATE_PROPERTY_SUCCESS:
            return {
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isCreated: true,
            };
        case UPDATE_PROPERTY_SUCCESS:
            return {
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isUpdated: true,
            };
        case DELETE_PROPERTY_SUCCESS:
            return {
                loading: false,
                status: action.payload.status,
                message: action.payload.message,
                isDeleted: true,
            };




        case PROPERTY_FAIL:
        case SINGLE_PROPERTY_FAIL:
        case CREATE_PROPERTY_FAIL:
        case UPDATE_PROPERTY_FAIL:
        case DELETE_PROPERTY_FAIL:
        case PACKAGES_FAIL:
            return {
                loading: false,
                errors: action.payload,
            };







        case CREATE_PROPERTY_RESET:
            return {
                isCreated: false,
            };
        case UPDATE_PROPERTY_RESET:
            return {
                isUpdated: false,
            };
        case DELETE_PROPERTY_RESET:
            return {
                isDeleted: false,
            };





        case CLEAR_ERRORS:
            return {
                ...state,
                errors: null,
            };
        default:
            return state;
    }
}