import React, { useEffect, useState } from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'

import { VendorRegisterAction, clearErrors } from '../../redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';

const VendorRegister = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading, status, message, isAuthenticated, user } = useSelector((state) => state.auth);
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirmation: ""
    })

    const inpChnage = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }

    const RegisterSubmit = (event) => {
        event.preventDefault();
        if (data.password !== data.passwordConfirmation) {
            toast.error("Password Confirmation does not matched...", { theme: "colored" });
        } else {
            dispatch(VendorRegisterAction(data.name, data.email, data.phone, data.password));
        }
    }


    let redirect = "";
    if (user && user.role === "Vendor") {
        redirect = "/vendor/dashboard";
    } else {
        redirect = "/admin/dashboard";
    }

    useEffect(() => {

        if (status && status === 501) {
            toast.error(message, { theme: "colored" });
            dispatch(clearErrors);
        }
        if (status && status === 500) {
            toast.error(message, { theme: "colored" });
            dispatch(clearErrors);
        }
        if (status && status === 201) {
            toast.success(message, { theme: "colored" });
            window.location.href = "/verify";
        }


        if (isAuthenticated === true) {
            window.location.href = redirect;
        }

    }, [dispatch, navigate, status, message, isAuthenticated, redirect])

    return (
        loading ? <Loading /> :
            <App>
                <MetaData title={'Vendor - Register'} />
                <CommonBanner heading={'Vendor - Register'} />
                <section id="common_author_area" className="section_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="common_author_boxed">
                                    <div className="common_author_heading">
                                        <h2>Vendor - Register</h2>
                                    </div>
                                    <div className="common_author_form">
                                        <form method='POST' onSubmit={RegisterSubmit} id="main_author_form">
                                            <div className="form-group">
                                                <input type="text" name="name" onChange={inpChnage} value={data.name} className="form-control" placeholder="Name" autoFocus required />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" name="email" onChange={inpChnage} value={data.email} className="form-control" placeholder="Email" required />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" name="phone" onChange={inpChnage} value={data.phone} className="form-control" placeholder="Phone" required />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name="password" onChange={inpChnage} value={data.password} className="form-control" placeholder="Password" required />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name="passwordConfirmation" onChange={inpChnage} value={data.passwordConfirmation} className="form-control" placeholder="Password Confirmation" required />
                                            </div>
                                            <div className="common_form_submit">
                                                <button type='submit' className="btn btn_theme btn_md">Vendor - Register</button>
                                            </div>

                                            <div className="have_acount_area">
                                                <p>Have a acccount ? <a href="/login">Login</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </App>
    )
}

export default VendorRegister
