import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommonBanner from '../../components/CommonBanner';
import Loading from '../../components/Loading';
import MetaData from "../../components/MetaData";
import { SinglePropertyAction } from '../../redux/actions/Admin/PropertyAction';
import App from './layouts/App';
const PropertyDetail = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { loading, property } = useSelector((state) => state.property);


    const [mainImageSrc, setMainImageSrc] = useState(property?.galleries[0]?.image);

    const handleThumbnailClick = (newSrc) => {
        setMainImageSrc(newSrc);
    };

    useEffect(() => {
        dispatch(SinglePropertyAction(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!loading) {
            setMainImageSrc(property?.galleries[0]?.image);
        }
    }, [loading, property])

    return (

        <App>
            <MetaData title={property?.name} />
            <CommonBanner heading={property?.name} />


            {/* <Packages /> */}

            {
                loading ? <Loading /> : (
                    <section id="tour_details_main" class="section_padding">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="tour_details_leftside_wrapper">
                                        <div class="tour_details_heading_wrapper">
                                            <div class="tour_details_top_heading">
                                                <h2>{property?.name}</h2>
                                                <h5><i class="fas fa-map-marker-alt"></i> {property?.address}</h5>
                                            </div>

                                        </div>
                                        <div class="tour_details_img_wrapper">
                                            <div class="slider slider-for">
                                                <div>
                                                    <img style={{ width: "100%", height: 400 }} id='mainImage' src={mainImageSrc} alt="img" />
                                                </div>
                                            </div>
                                            <div class="slider slider-nav d-flex flex-row">

                                                {
                                                    property?.galleries?.map((gallery, index) => (
                                                        <div key={index} style={{ marginRight: 10, cursor: 'pointer', width: "100%" }} onClick={() => handleThumbnailClick(gallery?.image)}>
                                                            <img className='img-thumbnail shadow-sm w-100 h-100' style={{ width: "100%" }} src={gallery?.image} alt="img" />
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                        <div class="tour_details_boxed">
                                            <h3 class="heading_theme">Overview</h3>
                                            <div class="tour_details_boxed_inner">
                                                <div dangerouslySetInnerHTML={{ __html: property?.description }} />
                                            </div>
                                        </div>


                                        <div class="tour_details_boxed">
                                            <h3 class="heading_theme">Other Info</h3>
                                            <div class="tour_details_boxed_inner">

                                                <ul>
                                                    <li><i class="fas fa-circle"></i><p><b>Rooms : </b>{property?.rooms}</p></li>
                                                    <li><i class="fas fa-circle"></i><p><b>Deposit  : </b>{property?.deposit}</p></li>
                                                    <li><i class="fas fa-circle"></i><p><b>Type  : </b>{property?.type}</p></li>
                                                    <li><i class="fas fa-circle"></i><p><b>Key Money  : </b>{property?.key_money}</p></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="tour_details_right_sidebar_wrapper">
                                        <div class="tour_detail_right_sidebar">
                                            <div class="tour_details_right_boxed">
                                                <div class="tour_details_right_box_heading">
                                                    <h3>Price</h3>
                                                </div>
                                                <div class="tour_package_bar_price">

                                                    <h3>${property?.price}</h3>
                                                </div>

                                                <div class="tour_package_details_bar_list">
                                                    <h5>Facilities</h5>
                                                    <ul>
                                                        {
                                                            property?.facilities.length > 0 && JSON.parse(property?.facilities).map((facility, index) => (

                                                                <li key={index}><i class="fas fa-circle"></i>{facility}</li>

                                                            ))
                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

        </App>
    )
}

export default PropertyDetail
