import React from 'react'
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import Loading from '../../../../components/Loading';

const Header = () => {
    const { loading, isAuthenticated } = useSelector((state) => state.auth);
    const role = localStorage.getItem("role");
    return (
        loading ? <Loading /> :
            <header className="main_header_arae">
                <div className="topbar-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <ul className="topbar-list">

                                    <li><Link to="#"><span>+91 82646 53729</span></Link></li>
                                    <li><Link to="#"><span>aptopass@gmail.com</span></Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul className="topbar-others-options">
                                    <li>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>&nbsp;
                                        <Link to="#"><i className="fab fa-twitter-square"></i></Link>&nbsp;
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>&nbsp;
                                        <Link to="#"><i className="fab fa-linkedin"></i></Link>&nbsp;
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navbar-area">
                    <div className="main-responsive-nav">
                        <div className="container">
                            <div className="main-responsive-menu">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="/logo.png" alt="logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-navbar">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link className="navbar-brand" to="/">
                                    <img src="/logo.png" alt="logo" />
                                </Link>
                                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link">Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about" className="nav-link">About</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/packages" className="nav-link">Packages</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/plan" className="nav-link">Plan</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/property" className="nav-link">Property</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact" className="nav-link">Contact</NavLink>
                                        </li>
                                    </ul>
                                    <div className="others-options d-flex align-items-center">
                                        <div className="option-item">
                                            {
                                                isAuthenticated === true ? (
                                                    <a href={`/${role}/dashboard`} className="btn  btn_navber">Dashboard</a>
                                                ) : (
                                                    <a href="/login" className="btn  btn_navber">Login</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
    )
}

export default Header
