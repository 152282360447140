export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const STATUS_USER_REQUEST = "STATUS_USER_REQUEST";
export const STATUS_USER_SUCCESS = "STATUS_USER_SUCCESS";
export const STATUS_USER_RESET = "STATUS_USER_RESET";
export const STATUS_USER_FAIL = "STATUS_USER_FAIL";

export const SINGLE_USER_REQUEST = "SINGLE_USER_REQUEST";
export const SINGLE_USER_SUCCESS = "SINGLE_USER_SUCCESS";
export const SINGLE_USER_RESET = "SINGLE_USER_RESET";
export const SINGLE_USER_FAIL = "SINGLE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_RESET = "DELETE_USER_RESET";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
