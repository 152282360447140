export const PROPERTY_REQUEST = "PROPERTY_REQUEST";
export const PROPERTY_SUCCESS = "PROPERTY_SUCCESS";
export const PROPERTY_FAIL = "PROPERTY_FAIL";

export const PACKAGES_REQUEST = "PACKAGES_REQUEST";
export const PACKAGES_SUCCESS = "PACKAGES_SUCCESS";
export const PACKAGES_FAIL = "PACKAGES_FAIL";

export const SINGLE_PROPERTY_REQUEST = "SINGLE_PROPERTY_REQUEST";
export const SINGLE_PROPERTY_SUCCESS = "SINGLE_PROPERTY_SUCCESS";
export const SINGLE_PROPERTY_FAIL = "SINGLE_PROPERTY_FAIL";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_RESET = "CREATE_PROPERTY_RESET";
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_RESET = "UPDATE_PROPERTY_RESET";
export const UPDATE_PROPERTY_FAIL = "UPDATE_PROPERTY_FAIL";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_RESET = "DELETE_PROPERTY_RESET";
export const DELETE_PROPERTY_FAIL = "DELETE_PROPERTY_FAIL";





export const CLEAR_ERRORS = "CLEAR_ERRORS";
