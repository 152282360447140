import React, { useEffect } from 'react'
import App from '../layouts/App'
import MetaData from '../../../components/MetaData'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { DeleteBannerAction, GetBannersAction } from '../../../redux/actions/Admin/BannerAction';
import { DELETE_BANNER_RESET } from '../../../redux/constants/Admin/BannerConstant';
import moment from 'moment';

const AdminAds = () => {
    const dispatch = useDispatch();

    const { loading, banners, message, isDeleted } = useSelector((state) => state.banners);

    const DeleteBannerFunction = (id) => {
        if (window.confirm("Are you sure to delete ?")) {
            dispatch(DeleteBannerAction(id));
        }
    }

    useEffect(() => {
        if (isDeleted && isDeleted === true) {
            toast.error(message, { theme: "colored" });
            dispatch({ type: DELETE_BANNER_RESET })
        }
        dispatch(GetBannersAction())
    }, [dispatch, isDeleted, message]);

    return (
        <App>
            <MetaData title={'Admin - Ads'} />

            <div className="py-30 px-30 rounded-4 bg-white custom_shadow">
                <div className="tabs -underline-2 js-tabs">
                    <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div className="col-auto d-flex align-items-center justify-content-between w-100">
                            <button className="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Ads</button>
                            <a className='btn btn-sm rounded-4 bg-blue-1 text-white' href="/admin/ads/create"><i className="icon-plus text-12 text-white-1"></i> Create</a>
                        </div>

                    </div>

                    {
                        loading ? <Loading /> :
                            <div className="tabs__content pt-30 js-tabs-content">

                                <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-4 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Banner Image</th>
                                                    <th>Name</th>
                                                    <th>Link</th>
                                                    <th>CreatedAt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    banners?.map((banner) => (
                                                        <tr key={banner?._id}>
                                                            <td>
                                                                <img src={banner?.image?.length > 0 ? banner?.image : "/placeholder.jpg"} alt="image1" className="size-50 rounded-2 object-cover" />&nbsp;
                                                            </td>
                                                            <td>{banner?.title}</td>
                                                            <td>{banner?.link}</td>
                                                            <td>{moment(banner?.createAt).format('DD MMM yyyy, hh:mm A')}</td>

                                                            <td>
                                                                <div className="btn-group">
                                                                    <a href={`/admin/ads/update/${banner?._id}`} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-edit text-16 text-light-1"></i>
                                                                    </a>

                                                                    <button onClick={() => DeleteBannerFunction(banner?._id)} className="flex-center bg-light-2 rounded-4 size-35">
                                                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    }
                </div>



            </div>



        </App>
    )
}

export default AdminAds
