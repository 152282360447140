import React from 'react'
import App from './layouts/App'
import MetaData from "../../components/MetaData";
import CommonBanner from '../../components/CommonBanner';
const Contact = () => {
    return (
        <App>
            <MetaData title={'Contact'} />
            <CommonBanner heading={'Contact Us'} />

            <section id="contact_main_arae" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_center">
                                <h2>Contact with us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="contact_main_form_area" style={{marginTop: -70}}>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="contact_form">
                                    <form action="" id="contact_form_content">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control bg_input" placeholder="First name*" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control bg_input" placeholder="Last name*" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control bg_input"
                                                        placeholder="Email address (Optional)" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control bg_input"
                                                        placeholder="Mobile number*" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <textarea className="form-control bg_input" rows="5"
                                                        placeholder="Message"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <button type="button" className="btn btn_theme btn_md">Send message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="phone_tuch_area">
                                <h3>Stay in touch</h3>
                                <h3><a href="tel:+91 82646 53729">+91 82646 53729</a></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="contact_boxed">
                                <h6>Address</h6>
                                <h3>Banglore</h3>
                                <p>Road No.39 Awabin, Binnati Kishore, Banglore</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </App>
    )
}

export default Contact
